<div class="container">
    <div class="add-block">
        <button class="btn btn-primary add-btn" routerLink="add">Add new hotel</button>
    </div>

    <div class="resort-box">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="true">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
        <div class="nodata" *ngIf="allHotels?.length<1">
            No hotels available
        </div>
        <table class="table" *ngIf="allHotels?.length>0">
            <tr class="head-table">
                <th class="head-table-item">Name</th>
                <th class="head-table-item">Location</th>
                <th class="head-table-item">Rating</th>
                <th class="head-table-item">Action</th>

            </tr>
            <tr *ngFor="let hotel of allHotels">
                <td>{{hotel.name}}</td>
                <td>{{hotel.location_name}}</td>
                <td>{{hotel.rating| number:'1.2-2'}}</td>
                <td>
                    <div class="btn-action-1" [routerLink]="'details/'+hotel._id">
                        <mat-icon aria-label="Side nav toggle icon">launch</mat-icon>
                    </div>
                    <div class="btn-action" matTooltip="deletehotel" (click)="deleteHotel(hotel?._id)">
                        <mat-icon aria-label="Side nav toggle icon" style="font-size: 2.250em;">delete</mat-icon>
                    </div>
                </td>

            </tr>
        </table>


        <div class="naviagation">
            <button class="btn-nav" [disabled]="userpage<1" (click)="gotoPage('prev')">Prev</button>
            <span>{{userpage+1}}</span>
            <button class="btn-nav" [disabled]="allHotels?.length<10" (click)="gotoPage('next')">Next</button>
        </div>
    </div>

</div>
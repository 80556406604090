<div class="container">

    <h3 style="font-weight: 600;">Create Banner</h3>

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>

    <div class="form-group">
        <label class="label" for="location"> Title</label>
        <input type="text" [ngClass]="{'is-invalid': !title&&submitted}" class="form-control" [(ngModel)]="title">
        <div class="invalid-feedback">
            Please enter title
        </div>
    </div>


    <div class="filebox">

        <div *ngIf="banner==null">
            <label style=" border: 1px solid rgb(105, 104, 104); padding:5px;width: 100%;color: black!important;" for="file-upload" class="file-attach" [ngClass]="{'invalid-label': uploadedFiles.length==0&&submitted}">
                 <mat-icon aria-hidden="false" class="more-btn" aria-label="Example home icon">upload_file</mat-icon>
                 Upload media
            </label>
            <input type="file" id="file-upload" (change)="uploadContent($event,'media')" class="form-control d-none">
        </div>

        <div class="container" *ngIf="banner!=null" style="position: relative;">

            <img [src]="baseUrl+banner?.url" width="250px" height="250px" *ngIf="banner?.location=='LOCAL'&&banner!=null" alt="">
            <img [src]="banner?.url" width="250px" height="250px" *ngIf="banner?.location=='S3'&& banner!=null" alt="">
            <button class="btn btn-remove" *ngIf="banner!=null" (click)="remove()">Replace </button>





        </div>
    </div>






    <div class="form-group mt-4">
        <button class="btn btn-add" (click)="addbanner()"> ADD</button>
        <button class="btn btn-danger" (click)="close()"> cancel</button>
    </div>







</div>
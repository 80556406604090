import { Component, OnInit,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-editbloodbank',
  templateUrl: './editbloodbank.component.html',
  styleUrls: ['./editbloodbank.component.scss']
})
export class EditbloodbankComponent implements OnInit {
  location:any;
  name:any;
  contact:any;
  constructor(private ref:MatDialogRef<EditbloodbankComponent> ,@Inject(MAT_DIALOG_DATA) public data: any,private api:ApiService ){ }

  ngOnInit(): void {
    console.log(this.data);
    
    this.location=this.data.location;
    this.name=this.data.blood_bank_name;
    this.contact=this.data.contact_no;

  }


  edit(){
    let dat={
      "id":this.data._id,
      "location":this.location,
      "blood_bank_name":this.name,
      "contact_no" : this.contact
  }

    this.api.editBloodbank(dat).subscribe((res:any)=>{
      this.ref.close();
    },
    (err:any)=>{
      console.log(err);
    }
      )
    
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-editbanner',
  templateUrl: './editbanner.component.html',
  styleUrls: ['./editbanner.component.scss']
})
export class EditbannerComponent implements OnInit {

  title: any;
  enabled: any;
  banner: any;
  submitted: boolean = false;
  uploadedFiles: any = [];
  contents: any = [];
  mcontents: any = [];
  menucontents: any = [];
  baseUrl = environment.baseUrl+'files/';

  constructor(private ref:MatDialogRef<EditbannerComponent> ,@Inject(MAT_DIALOG_DATA) public data: any,private api:ApiService) { }

  ngOnInit(): void {
    console.log(this.data);
    
    this.title=this.data.title;
    this.banner=this.data.banner;
    this.enabled=this.data.enabled;

  }
  editbanner(){
    this.submitted = true;
   
    let dat={
      "id":this.data._id,
      "title": this.title,
      "banner": this.banner?._id,
      "enabled":this.enabled
               

}
this.api.editBanner(dat).subscribe((res:any)=>{
  this.ref.close(true);
},
(err:any)=>{
  console.log(err);
}
  )

}


uploadContent(event: any, typeselected: any) {
  console.log(event);
  let formData = new FormData();
  let type = '';
  if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png') {
    type = 'IMAGE';
  }
  else if (event.target.files[0].type == 'video/mp4') {
    type = 'VIDEO';
  }
  formData.append('file', event.target.files[0]);
  formData.append('type', type);
  this.api.uploadContent(formData).subscribe((res: any) => {
    console.log("RESPONSE : ", res);
    event.target.value = '';
    console.log(this.contents);
    let url = environment.baseUrl + 'files/' + res.data.url;
    this.banner = res?.data;
  },
    (err: any) => {
      console.log(err);
    }
  )
}
cancel(){
this.banner=null;
}

close(){
  this.ref.close(false);
}

  }

<mat-sidenav-container class="sidenav-container" #sidenavxx>
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-toolbar><img src="assets/gnpclogo.png" width="80px" height="auto" alt=""> </mat-toolbar>
        <mat-nav-list>
            <mat-list-item *ngIf="access.includes('SETTINGS')" role="listitem" (click)="close()" routerLink="overview" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">analytics</mat-icon> &nbsp; Overview
            </mat-list-item>
            <mat-list-item *ngIf="access.includes('USERS')" role="listitem" (click)="close()" routerLink="users/list" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">groups</mat-icon> &nbsp; Users
            </mat-list-item>
            <mat-list-item *ngIf="access.includes('POST')" role="listitem" (click)="close()" routerLink="posts" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">feed</mat-icon> &nbsp; Posts
            </mat-list-item>
            <mat-list-item *ngIf="access.includes('BLOOD')" role="listitem" (click)="close()" routerLink="bloodbank" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">bloodtype</mat-icon> &nbsp; Blood Bank
            </mat-list-item>



            <mat-list-item *ngIf="access.includes('HOTELS')" role="listitem" (click)="close()" routerLink="hospitality/hotels" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">storefront</mat-icon> &nbsp; Hospitality
            </mat-list-item>
            <mat-list-item *ngIf="access.includes('ENQUIRY')" role="listitem" (click)="close()" routerLink="enquiry" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">contact_support</mat-icon> &nbsp; Enquiry
            </mat-list-item>
            <mat-list-item *ngIf="access.includes('JOB')" role="listitem" (click)="close()" routerLink="jobs" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">work</mat-icon> &nbsp; jobs
            </mat-list-item>
            <mat-list-item *ngIf="access.includes('BANNERS')" role="listitem" (click)="close()" routerLink="banner" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">wallpaper</mat-icon> &nbsp; Banner
            </mat-list-item>

            <mat-list-item *ngIf="access.includes('SETTINGS')" role="listitem" (click)="close()" routerLink="settings" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">settings</mat-icon> &nbsp; Settings
            </mat-list-item>
            <mat-list-item *ngIf="access.includes('JOB')" role="listitem" (click)="close()" routerLink="reporting" routerLinkActive="is-active">
                <mat-icon aria-label="Side nav toggle icon">flag</mat-icon> &nbsp; Reporting
            </mat-list-item>




        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>

        <mat-toolbar color="primary">

            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span *ngIf="isHandset$|async">GNPC</span>
            <div class="route"> {{currentRoute}}</div>



            <button type="button" class="btn-logout" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                {{currUser?.name| slice:0:10}} <mat-icon>account_circle</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="handleSignOut()">
                    <mat-icon c aria-label="Side nav toggle icon">logout</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <!-- 
            <button type="button" class="btn-logout" aria-label="logout" mat-icon-button (click)="handleSignOut()">
              <mat-icon  c aria-label="Side nav toggle icon">logout</mat-icon>
            </button> -->
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
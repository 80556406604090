<div class="edit-block">

    <div>
        <button class="btn back-btn" routerLink="../..">
            <mat-icon aria-label="Side nav toggle icon">arrow_back_ios</mat-icon>

            Back
        </button>

    </div>

    <table class="table dtails">
        <tr>
            <th>Name</th>
            <td>
                <span *ngIf="editing!='name'">{{hoteldata?.name}}</span>
                <input *ngIf="editing=='name'" type="text" [(ngModel)]="hoteldata.name">
            </td>
            <td>
                <span *ngIf="editing!='name'" (click)="editing='name'">
                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span>
                <span *ngIf="editing=='name'" (click)="editHotel()">
                    <mat-icon aria-label="Side nav toggle icon">save</mat-icon>
                </span>
            </td>
        </tr>
        <tr>
            <th>Location</th>
            <td>
                <span *ngIf="editing!='locationname'">{{hoteldata?.location_name}}</span>
                <input *ngIf="editing=='locationname'" type="text" [(ngModel)]="hoteldata.location_name">
            </td>
            <td>
                <span *ngIf="editing!='locationname'" (click)="editing='locationname'">
                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span>
                <span *ngIf="editing=='locationname'" (click)="editHotel()">
                    <mat-icon aria-label="Side nav toggle icon">save</mat-icon>
                </span>
            </td>
        </tr>
        <tr>
            <th>Rating</th>
            <td>{{hoteldata?.rating| number:'1.2-2'}}</td>
            <td>
                <mat-icon routerLink="rating" aria-label="Side nav toggle icon">launch</mat-icon>
            </td>
        </tr>
        <tr>
            <th>Description</th>
            <td><span *ngIf="editing!='description'">{{hoteldata?.description}}</span>
                <input *ngIf="editing=='description'" type="text" [(ngModel)]="hoteldata.description">
            </td>
            <td>
                <span *ngIf="editing!='description'" (click)="editing='description'">
                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span>
                <span *ngIf="editing=='description'" (click)="editHotel()">
                    <mat-icon aria-label="Side nav toggle icon">save</mat-icon>
                </span>
            </td>
        </tr>
        <tr>
            <th>Website</th>
            <td><span *ngIf="editing!='website'">{{hoteldata?.website}}</span>
                <input *ngIf="editing=='website'" type="text" [(ngModel)]="hoteldata.website">
            </td>
            <td>
                <span *ngIf="editing!='website'" (click)="editing='website'">
                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span>
                <span *ngIf="editing=='website'" (click)="editHotel()">
                    <mat-icon aria-label="Side nav toggle icon">save</mat-icon>
                </span>
            </td>
        </tr>
        <tr>
            <th>Open time</th>
            <td><span *ngIf="editing!='open_time'">{{hoteldata?.open_time}}</span>
                <input *ngIf="editing=='open_time'" type="text" [(ngModel)]="hoteldata.open_time">
            </td>
            <td>
                <span *ngIf="editing!='open_time'" (click)="editing='open_time'">
                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span>
                <span *ngIf="editing=='open_time'" (click)="editHotel()">
                    <mat-icon aria-label="Side nav toggle icon">save</mat-icon>
                </span>
            </td>
        </tr>
        <tr>
            <th>Close time</th>
            <td><span *ngIf="editing!='close_time'">{{hoteldata?.close_time}}</span>
                <input *ngIf="editing=='close_time'" type="text" [(ngModel)]="hoteldata.close_time">
            </td>
            <td>
                <span *ngIf="editing!='close_time'" (click)="editing='close_time'">
                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span>
                <span *ngIf="editing=='close_time'" (click)="editHotel()">
                    <mat-icon aria-label="Side nav toggle icon">save</mat-icon>
                </span>
            </td>
        </tr>
        <!-- <tr>
            <th>Menu</th>
            <td><span *ngIf="editing!='menu'">{{hoteldata?.menu}}</span>

                <textarea *ngIf="editing=='menu'" name="menu" id="menu" [(ngModel)]="hoteldata.menu" class="form-control" cols="20" rows="3"></textarea>
            </td>
            <td>
                <span *ngIf="editing!='menu'" (click)="editing='menu'">
                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span>
                <span *ngIf="editing=='menu'" (click)="editHotel()">
                    <mat-icon aria-label="Side nav toggle icon">save</mat-icon>
                </span>
            </td>
        </tr> -->
        <tr>
            <th>Position</th>
            <td><span *ngIf="editing!='position'">{{hoteldata?.position}}</span>

                <input *ngIf="editing=='position'" type="text" [(ngModel)]="hoteldata.position">
            </td>
            <td>
                <span *ngIf="editing!='position'" (click)="editing='position'">
                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span>
                <span *ngIf="editing=='position'" (click)="updatePosition()">
                    <mat-icon aria-label="Side nav toggle icon">save</mat-icon>
                </span>
            </td>
        </tr>

        <tr>
            <td>Location coordinates</td>
            <td>{{hoteldata?.location.coordinates[0]}},{{hoteldata?.location.coordinates[1]}}</td>
            <td> <span class="d-flex" (click)="updateCoordinate()">

                    <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
                </span></td>
        </tr>

        <!-- <div class="container mt-4">

       
    </div> -->

    </table>



    <div class="mt-4" style="border-top: 1px solid #28282d; padding-top: 20px;" *ngIf="hotelmenus.length>0">
        <div class="d-flex" style="justify-content: space-between;">

            <label for="menu">Menu</label>
            <span (click)="editMenu()" style="margin-right:20px;">
                <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
            </span>

        </div>
        <ng-image-slider [images]="hotelmenus" #nav></ng-image-slider>

    </div>
    <div class="mt-4" style="border-top: 1px solid #28282d; padding-top: 20px;" *ngIf="hotelmedias.length>0">
        <div class="d-flex" style="justify-content: space-between;">

            <label for="menu">Media</label>
            <span (click)="editMedia()" style="margin-right:20px;">
                <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
            </span>

        </div>
        <ng-image-slider [images]="hotelmedias" #nav></ng-image-slider>





    </div>


</div>
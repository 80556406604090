import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from '@angular/material/dialog';
import { UserActionsComponent } from './user-actions/user-actions.component';
import { ConfirmboxComponent } from 'src/app/confirmbox/confirmbox.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(private api: ApiService, private spinner: NgxSpinnerService, private dialog: MatDialog) { 
    this.api.usersectiontab$.next('list');
  }
  userList: any;
  isLoading: boolean = true;
  userpage: any = 0;
  pagelist: any = [1];
  ngOnInit(): void {
    this.spinner.show();
    this.listUsers();

  }


  listUsers() {
    console.log("page number", this.userpage);
    
    this.spinner.show();
    this.api.getUsers(this.userpage).subscribe(
      (data: any) => {
        console.log(data);
        if (data['page'] > 0) {
          this.userList = data['data'];
          if(!this.pagelist.includes(data['page'])){
            this.pagelist.push(data['page']);

          }
        }
        else {
          this.userpage = 0;
          this.api.getUsers(this.userpage).subscribe(
            (data: any) => {
              console.log(data);
              this.userList = data['data'];
            });
        }
        this.spinner.hide();
      },
      (error) => {
        console.log(error);

        this.spinner.hide();
      }
    );
    console.log("page list",this.pagelist);
    
  }

  gotoPage(to: any) {
    if (to == 'next') {
      this.userpage++;
    }
    else {
      this.userpage--;
    }

    this.listUsers();
  }

  gotopagebynumber(page:any){
    console.log(page);
    
    this.userpage = page-1;
    this.listUsers();
  }
  


  actionBox(user: any) {
    const dialogRef = this.dialog.open(UserActionsComponent, {
      disableClose: true,
      data: user
    }
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  verifyUser(id: any,sts:any,name:any) {
   let status=sts==true?false:true;
    let data = {
      "user_id": id,
      "verification": status
    };
    this.spinner.show();
    this.api.verifyuser(data).subscribe((data: any) => {
      console.log(data);
      if(status==true){
      this.api.openSnackBar(3,"User "+name+" verified successfully", "X");
      }
      else{
        this.api.openSnackBar(3,"User "+name+" verification removed", "X");
      }
      this.spinner.hide();
      this.listUsers();
    },
      err => {
        console.log(err);
        this.spinner.hide();
        this.api.openSnackBar(3,"Something went wrong !!", "X");
      }
    );
  }

  changeRole(id: any,sts:any) {
    this.spinner.show();
   let role=sts=='admin'?'user':'admin';
    let data = {
      "id": id,
      "role": role
    };
    this.api.rolechange(data).subscribe((data: any) => {
      console.log(data);
      this.api.openSnackBar(3,"Role changed successfully", "ok");
      this.spinner.hide();
      this.listUsers();
    },
      err => {
        console.log(err);
      }
    );
  }




  deleteUser(id: any) {

      this.dialog.open(ConfirmboxComponent, {
        data: {
          tasktype: 'delete',
          btnmsg: 'Delete',
          message: 'Are you sure you want to delete user?',
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
    
      this.spinner.show();
      this.api.deleteUser(id).subscribe((data: any) => {
        console.log(data);
        this.spinner.hide();
        this.api.openSnackBar(3,"User deleted successfully", "ok");
        
        // this.ref.close();
      }, (err: any) => {
        console.log(err);
        this.spinner.hide();
        this.api.openSnackBar(3,"Something went wrong !!", "ok");
      }
      );
    }
    else {
      this.spinner.hide();
    }

      });
  }

  blockUser(id: any,sts:any) {
   let  status=sts==true?false:true;
    let data= {
      'id':id,
      "blocked":status
    };
    this.spinner.show();
    let msg = status == true ? "Are you sure you want to block this user?" : "Are you sure you want to unblock this user?";
    this.dialog.open(ConfirmboxComponent, {
      data: {
        tasktype: 'delete',
        btnmsg: 'Continue',
        message: msg,
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
  
      this.api.blockUser(data).subscribe((data: any) => {
        console.log(data);
        this.spinner.hide();
        if(status==true){
          this.api.openSnackBar(3,"User blocked successfully", "ok");
        }
        else{
          this.api.openSnackBar(3,"User unblocked successfully", "ok");
        }
        
        this.listUsers();
        // this.ref.close();
      }, (err: any) => {
        console.log(err);
        this.spinner.hide();
        this.api.openSnackBar(3,"Something went wrong !!", "ok");
      }
      );
    }
    else {
      this.spinner.hide();
    }
  });
  }


}



<div class="container outer">
    <!-- <h4> {{data?.name}}</h4> -->


    <div class="container">
        <div class="action">
            <div>Delete Hotel</div>
            <div class="btn-action" (click)="deleteHotel()">
                <mat-icon aria-label="Side nav toggle icon">delete</mat-icon>
            </div>
        </div>
    </div>
    <button class="btn btn-danger" (click)="close()">
        Close
    </button>
</div>
    
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @ViewChild('startdate') startdate:any;
  @ViewChild('enddate') enddate:any;
  constructor(private ref:MatDialogRef<FilterComponent>) { }

  ngOnInit(): void {
  }


close(){
  if(this.startdate.nativeElement.value && this.enddate.nativeElement.value){

  console.log(this.startdate.nativeElement.value);
  console.log(this.enddate.nativeElement.value);
  this.ref.close({startdate:this.startdate.nativeElement.value,enddate:this.enddate.nativeElement.value});

  }
  // this.ref.close();
  else{
    alert('Please Select Date');
  }

 
}




cancel(){
  this.ref.close();
}

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-addbanner',
  templateUrl: './addbanner.component.html',
  styleUrls: ['./addbanner.component.scss']
})
export class AddbannerComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  title: any;
  banner: any;
  menucontents: any = [];
  contents: any = [];
  mcontents: any = [];
  uploadedFiles: any = [];
   submitted: boolean = false;
 
  baseUrl = environment.baseUrl+'files/';
  constructor(private snackBar:MatSnackBar,private spinner:NgxSpinnerService, private api:ApiService,private ref:MatDialogRef<AddbannerComponent>,@Inject (MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  
  addbanner(){
    this.submitted = true;
    if (this.title == undefined || this.title == '' || this.banner == undefined || this.banner == ''|| this.banner == null) {
      this.openSnackBar(2, 'Please update all data before submit', 'ok');
      return;
    }
    let dat={
      "title":this.title,
      "banner":this.banner?._id
      }
    this.api.addBanners(dat).subscribe(res=>{
      this.ref.close(true);
      this.openSnackBar(2, 'New banner added successfully', 'ok');
    },
    err=>{
      console.log(err);
      this.openSnackBar(2, 'Something went wrong', 'ok');
    })
  }



  uploadContent(event: any, typeselected: any) {
    console.log(event);

    let formData = new FormData();
    let type = '';
    if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png') {
      type = 'IMAGE';
    }
    else if (event.target.files[0].type == 'video/mp4') {
      type = 'VIDEO';
    }
    formData.append('file', event.target.files[0]);
    formData.append('type', type);
    this.spinner.show();
    this.api.uploadContent(formData).subscribe((res: any) => {
      this.spinner.hide();
      console.log("RESPONSE : ", res);
      this.banner = res?.data;
      event.target.value = '';
      console.log(this.contents);
      let url = environment.baseUrl + 'files/' + res.data.url;
     

    },
      (err: any) => {
        console.log(err);
        this.spinner.hide();
      }

    )
  }
  openSnackBar(duration: any, msg: any, action: any) {
    this.snackBar.open(msg, action, {
      duration: duration * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }


  remove(){
    this.banner=null;
  }

  close(){
    this.ref.close(false);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hospitality',
  templateUrl: './hospitality.component.html',
  styleUrls: ['./hospitality.component.scss']
})
export class HospitalityComponent implements OnInit {

  constructor(private router:Router,private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    // this.router.navigate(['hotels'], {relativeTo: this.activatedRoute});
  }




// add hotel

// {
//   "id": "61b88b8b49ef5fe72d0372aa",
//   "name": "test",
//   "type":"resort",
//   "description": "test",
//   "location_name": "Anayara",
//   "files": [
//      "61b88b6949ef5fe72d0372a9"
//   ],
//   "website": "www.google.com",
//   "menu": "sample : 100 rs",
//   "open_time": "10:10:10",
//   "close_time": "11:11:!1",
//   "location": {
//       "type": "Point",
//       "coordinates": [
//           122.56667,
//           111.2313
//       ]
//   }
// }



// get all hotels

// {
//   "data": [
//       {
//           "location": {
//               "coordinates": [
//                   122.56667,
//                   111.2313
//               ],
//               "type": "Point"
//           },
//           "location_name": "Anayara",
//           "files": [
//               {
//                   "url": "https://gnpc.s3.amazonaws.com/619de085d41190fb5bc27d4d-1639484264996.jpeg",
//                   "location": "S3",
//                   "_id": "61b88b6949ef5fe72d0372a9",
//                   "title": "File",
//                   "type": "IMAGE",
//                   "thumbnail": "619de085d41190fb5bc27d4d-1639484264996.jpeg",
//                   "creater": "619de085d41190fb5bc27d4d",
//                   "createdAt": "2021-12-14T12:17:45.030Z",
//                   "updatedAt": "2021-12-14T12:35:04.735Z",
//                   "__v": 0
//               }
//           ],
//           "website": "www.google.com",
//           "menu": "sample : 100 rs",
//           "type": "hotel",
//           "open_time": "10:10:10",
//           "close_time": "11:11:!1",
//           "position": 0,
//           "rating": 4.714285714285714,
//           "_id": "61b88bd549ef5fe72d0372ab",
//           "name": "test",
//           "description": "test",
//           "createdAt": "2021-12-14T12:19:33.206Z",
//           "updatedAt": "2021-12-14T13:21:56.293Z",
//           "__v": 0
//       }
//   ],
//   "message": "Success",
//   "page": 1
// }

}

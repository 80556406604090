<div class="container bdy">
    <h3>User Permissions</h3>
    <div class="form-group">
        <label class="label" for="name">POST</label>
        <input type="checkbox" class="form-control" (change)="update($event,'POST')" [checked]="this.data?.permissions.includes('POST')" name="POST" id="POST">
    </div>
    <div class="form-group">
        <label class="label" for="USERS">USERS</label>
        <input type="checkbox" class="form-control" (change)="update($event,'USERS')" [checked]="this.data?.permissions.includes('USERS')" name="USERS" id="USERS">
    </div>
    <div class="form-group">
        <label class="label" for="name">JOB</label>
        <input type="checkbox" class="form-control" (change)="update($event,'JOB')" [checked]="this.data?.permissions.includes('JOB')" name="JOB" id="JOB">
    </div>
    <div class="form-group">
        <label class="label" for="name">SETTINGS</label>
        <input type="checkbox" class="form-control" (change)="update($event,'SETTINGS')" [checked]="this.data?.permissions.includes('SETTINGS')" name="SETTINGS" id="SETTINGS">
    </div>
    <div class="form-group">
        <label class="label" for="name">HOTELS</label>
        <input type="checkbox" class="form-control" (change)="update($event,'HOTELS')" [checked]="this.data?.permissions.includes('HOTELS')" name="HOTELS" id="HOTELS">
    </div>
    <div class="form-group">
        <label class="label" for="name">BLOOD</label>
        <input type="checkbox" class="form-control" (change)="update($event,'BLOOD')" [checked]="this.data?.permissions.includes('BLOOD')" name="BLOOD" id="BLOOD">
    </div>

    <div class="form-group">
        <label class="label" for="name">ENQUIRY</label>
        <input type="checkbox" class="form-control" (change)="update($event,'ENQUIRY')" [checked]="this.data?.permissions.includes('ENQUIRY')" name="ENQUIRY" id="ENQUIRY">
    </div>
    <!-- <div class="form-group">
        <label class="label" for="name">JOB</label>
        <input type="checkbox" class="form-control" (change)="update($event,'JOB')" [checked]="this.data?.permissions.includes('JOB')" name="JOB" id="JOB">
    </div> -->
    <div class="form-group">
        <label class="label" for="name">REPORTNG</label>
        <input type="checkbox" class="form-control" (change)="update($event,'REPORTING')" [checked]="this.data?.permissions.includes('REPORTING')" name="REPORTING" id="REPORTING">
    </div>
    <div class="form-group">
        <label class="label" for="name">BANNERS</label>
        <input type="checkbox" class="form-control" (change)="update($event,'BANNERS')" [checked]="this.data?.permissions.includes('BANNERS')" name="BANNERS" id="BANNERS">
    </div>

    <div class="btn-block">

        <button class="btn btn-primary" (click)="updatePermission()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            <span *ngIf="loading">Updating...</span>
            <span *ngIf="!loading">UPDATE PERMISSIONS</span>
        </button>

    </div>





</div>
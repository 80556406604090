<div class="container" class="outer-box">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <table class="table">
        <tr class="head-table">
            <th class="head-table-item">Name</th>
            <th class="head-table-item">Email</th>
            <th class="head-table-item">Contact</th>
            <th class="head-table-item">Message</th>
            <th class="head-table-item">Type</th>
            <th class="head-table-item">Action</th>
        </tr>
        <tr *ngFor="let enquiry of enq">
            <td>{{enquiry.name}}</td>
            <td>{{enquiry.email}}</td>
            <td>{{enquiry.contact}}</td>
            <td>{{enquiry.message}}</td>
            <td>{{enquiry.type}}</td>
            <td>
                <button class="btn" [ngClass]="{'btn-secondary':enquiry?.status=='PENDING'}" (click)="markAsCompleted(enquiry?._id)">
                    <mat-icon aria-label="Side nav toggle icon" class="addicon" >done</mat-icon>
                    
                </button>
            </td>


        </tr>
    </table>
    <!-- <div class="naviagation">
        <button class="btn-nav" [disabled]="enquirypage<1" (click)="gotoPage('prev')">Prev</button>
        <span>{{enquirypage+1}}</span>
        <button class="btn-nav" [disabled]="enq.length<10" (click)="gotoPage('next')">Next</button>
    </div> -->
</div>
<div class="container posts-box">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>

    <div class="typechange">
        <button class="btn" [ngClass]="{'tab_btn' : showPost == 'pending','tab_btn1' : showPost == 'all'}" (click)="showPost='pending'">Pending</button>

        <button class="btn" [ngClass]="{'tab_btn' : showPost == 'all','tab_btn1' : showPost == 'pending'}" (click)="showPost='all'">All</button>
    </div>

    <div *ngIf="showPost=='pending'">
        <div *ngIf="noPendingPost" class="nopost"> No Pending Posts available</div>
        <div class="container" *ngIf="pendingPostList">
            <!-- (click)="postDetails(post?._id)" -->
            <div class="post-tab-out" *ngFor="let post of pendingPostList.post">
                <div class="user-info">

                    <div>
                        <div class="user-icon">
                            {{post?.owner?.name[0]| uppercase}}
                        </div>
                        <span style="font-size: 12px; color: grey;">{{post?.owner?.name}}</span>
                    </div>

                    <div class="d-flex">
                        <div class="mx-3"><button class="btn btn-secondary btn-approve" (click)="approvePost(post?._id)">Approve</button>
                        </div>
                        <div>
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="approvePostwithNotification(post?._id)">
                                    <mat-icon>notifications_active</mat-icon>
                                    Approve post with Notification
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                </div>
                <div class="post-tab">
                    <div class="title-content" (click)="postDetails(post?._id)" [innerHtml]="post?.title"></div>

                    <div class="contents">

                        <ng-image-slider *ngIf="post?.contents.length > 0" [images]="postcontents[post?._id]" #nav>
                        </ng-image-slider>

                    </div>

                </div>
            </div>
        </div>
        <div class="naviagation">
            <button class="btn-nav" [disabled]="userpagePending<1" (click)="gotoPage('prev')">Prev</button>
            <div class="text-center" style="margin: auto;">{{userpagePending+1}}</div>
            <button class="btn-nav" [disabled]="pendingPostList?.post?.length<10" (click)="gotoPage('next')">Next</button>
        </div>
    </div>


    <div *ngIf="showPost=='all'">

        <div class="filter-box">
            <div class="d-flex" style="align-items: center;">
                <button class="btn" style="color: white;">Filter <mat-slide-toggle [checked]="isFilter"
                        (change)="filterPost()"></mat-slide-toggle></button>
                <div *ngIf="filterStart"><span style="font-weight: 600;">From :</span> {{filterStart}} </div>
                <div *ngIf="filterEnd"><span style="font-weight: 600;"> &nbsp;To :</span> {{filterEnd}} </div>
            </div>
            <!-- <div>
                <button class="btn" matTooltip="clear filter" style="color: white;" *ngIf="isFilter" (click)="clearFilter()">
                    <mat-icon>filter_alt_off</mat-icon>
                </button>
            </div> -->
        </div>



        <div *ngIf="noPost" class="nopost"> No Posts available</div>
        <div class="container" *ngIf="allPosts">
            <div class="post-tab-out" *ngFor="let post of allPosts.post">
                <!-- (click)="postDetails(post?._id)" -->
                <div class="user-info">
                    <div>

                        <div class="user-icon">
                            {{post?.owner?.name[0]| uppercase}}
                        </div>
                        <span style="font-size: 12px; color: grey;">{{post?.owner?.name}}</span>
                    </div>


                    <div>

                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button class="btn" (click)="pushForPost(post?._id)">
                                <mat-icon>notifications_active</mat-icon>
                                Push Notification
                            </button>


                            <button class="btn" mat-menu-item [matTooltip]="!post?.comment_off?'turn off comment':'turn on comment'" (click)="commentToggle(post?._id,post?.comment_off)">
                                <mat-icon *ngIf="post?.comment_off">comments_disabled</mat-icon>
                                <mat-icon *ngIf="!post?.comment_off">comment</mat-icon>
                                {{post?.comment_off?'Enable':'Disable'}}
                            </button>

                            <button class="btn" mat-menu-item [matTooltip]="post?.pinned?'Unpin post':'Pin post'" (click)="pinUpin(post?._id,post?.pinned)" [ngClass]="{'unpin':!post?.pinned,'pin':post?.pinned}">
                                <mat-icon>push_pin</mat-icon> {{post?.pinned?'Unpin':'Pin'}}
                            </button>
                            <button class="btn" mat-menu-item matTooltip="Delete post" (click)="deletePost(post?._id)">
                                <mat-icon>delete</mat-icon> Delete
                            </button>
                        </mat-menu>
                        <!--  <button mat-menu-item matTooltip="Delete post" (click)="deletePost(post?._id)">
                                <mat-icon>delete</mat-icon> Delete
                      
                         </button>
                            <button mat-menu-item class="btn" [matTooltip]="post?.pinned?'Unpin post':'Pin post'" (click)="pinUpin(post?._id,post?.pinned)" [ngClass]="{'unpin':!post?.pinned,'pin':post?.pinned}">
                            <mat-icon>push_pin</mat-icon>
                  
                     </button>
 -->


                        <!-- <button mat-menu-item>
                          <mat-icon>comments_disabled</mat-icon>
                          Disable comments
                          <mat-slide-toggle class="example-margin" (change)="commentToggle($event)" [color]="color" [checked]="data?.comment_off" [disabled]="disabled">
                           
                        </mat-slide-toggle>
                        </button> -->
                        <!-- 
                        </mat-menu> -->


                    </div>

                </div>
                <div class="post-tab">
                    <div class="title-content" (click)="postDetails(post?._id)" [innerHtml]="post?.title"></div>
                    <div class="contents">

                        <ng-image-slider *ngIf="post?.contents.length > 0" [images]="postcontents[post?._id]" #nav>
                        </ng-image-slider>

                    </div>
                </div>

                <div class="post-footer">

                    <div class="d-flex" style="align-items: center;">
                        <mat-icon style="font-size: 15px; height: 15px;width: 15px; color: #ffc107;">favorite_border
                        </mat-icon> {{post?.like}}
                    </div>
                    <div class="d-flex mx-2" style="align-items: center;">
                        <mat-icon style="font-size: 15px; height: 15px; width: 15px; color: #ffc107;">chat</mat-icon>
                        {{post?.comments}}
                    </div>
                    <div style=" font-size:10px;color: lightgrey;">{{post?.createdAt |date:'short'}}</div>

                </div>
            </div>
        </div>

        <div class="naviagation">
            <button class="btn-nav" [disabled]="userpage<1" (click)="gotoPage('prev')">Prev</button>
            <div class="text-center" style="margin: auto;">{{userpage+1}}</div>
            <button class="btn-nav" [disabled]="allPosts?.post?.length<10" (click)="gotoPage('next')">Next</button>
        </div>
    </div>



</div>
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss']
})
export class UserActionsComponent implements OnInit {

  constructor(private ref:MatDialogRef<UserActionsComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private api:ApiService) { }

  ngOnInit(): void {
    console.log(this.data);
    
  }


  close(){
    this.ref.close();
  }

  deleteUser(){
    if(confirm("Are you sure you want to delete this user?")){
   
    this.api.deleteUser(this.data.id).subscribe((data:any)=>{
      console.log(data);
      
      // this.ref.close();
    },(err:any)=>{
      console.log(err);
    }
    );
  }
  else{

  }

  }

  blockUser(){
    if(confirm("Are you sure you want to block this user?")){
    this.api.blockUser(this.data.id).subscribe((data:any)=>{
      console.log(data);
      
      // this.ref.close();
    },(err:any)=>{
      console.log(err);
    }
    );
  }
  else{
    
  }
  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {
 
  activeRoute:any='users';
  constructor(private router:Router,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.router.navigate(['reporting-list'], {relativeTo: this.route});
  }
  goto(path:string){
    this.router.navigate(['reporting/'+path]);
    this.activeRoute = path;
  }

  }




import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { MappickerComponent } from '../add/mappicker/mappicker.component';
import { MediaEditComponent } from './media-edit/media-edit.component';
@Component({
  selector: 'app-details-view',
  templateUrl: './details-view.component.html',
  styleUrls: ['./details-view.component.scss']
})
export class DetailsViewComponent implements OnInit {

  constructor(private api: ApiService, private route: ActivatedRoute, private dailog: MatDialog) { }
  hoteldata: any;
  hotelId: any;
  baseUrl = environment.baseUrl + 'files/';
  editing: any = '';
  hotelmedias: any = [];
  hotelmenus: any = [];
  ngOnInit(): void {
    this.hotelId = this.route.snapshot.paramMap.get('id');
    this.getHotelbyid(this.hotelId);
  }

  getHotelbyid(id: any) {
    this.api.getHotelbyId(id).subscribe((res: any) => {
      console.log("data ::", res);
      this.hoteldata = res['data'];
      let ctemp: any = [];
      res?.data?.files.forEach((ele: any) => {
        if (ele.location == 'LOCAL') {
          if (ele.type == 'IMAGE') {
            ctemp.push(
              {
                location: "LOCAL",
                alt: 'image',
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v,
                image: this.baseUrl + ele.url,
                thumbImage: this.baseUrl + ele.url,
              }
            );
          }
          else {
            ctemp.push(
              {
                location: "LOCAL",
                alt: 'video',
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v,
                video: this.baseUrl + ele.url,
                thumbImage: this.baseUrl + ele.url,
              }
            );
          }
        }
        else {
          if (ele.type == 'IMAGE') {
            ctemp.push(
              {
                location: "S3",
                alt: 'image',
                image: ele.url,
                thumbImage: ele.url,
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v
              }
            );
          }
          else {
            ctemp.push(
              {
                location: "S3",
                alt: 'video',
                video: ele.url,
                thumbImage: ele.url,
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v
              }
            );
          }
        }
      });
      this.hotelmedias = ctemp;
      console.log("created array ", this.hotelmedias);

      let mtemp: any = [];
      res?.data?.menus.forEach((ele: any) => {
        if (ele.location == 'LOCAL') {
          if (ele.type == 'IMAGE') {
            mtemp.push(
              {
                location: "LOCAL",
                alt: 'image',
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v,
                image: this.baseUrl + ele.url,
                thumbImage: this.baseUrl + ele.url,
              }
            );
          }
          else {
            mtemp.push(
              {
                location: "LOCAL",
                alt: 'video',
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v,
                video: this.baseUrl + ele.url,
                thumbImage: this.baseUrl + ele.url,
              }
            );
          }
        }
        else {
          if (ele.type == 'IMAGE') {
            mtemp.push(
              {
                location: "S3",
                alt: 'image',
                image: ele.url,
                thumbImage: ele.url,
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v
              }
            );
          }
          else {
            mtemp.push(
              {
                location: "S3",
                alt: 'video',
                video: ele.url,
                thumbImage: ele.url,
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v
              }
            );
          }
        }
      });
      this.hotelmenus = mtemp;
      console.log(this.hotelmenus);



    },
      (err: any) => {
        console.log(err);
      }

    )
  }


  editHotel() {

    this.hoteldata.id = this.hoteldata._id;

    console.log(this.hoteldata);


    this.api.editHotel(this.hoteldata).subscribe((res: any) => {
      console.log("updated", res);
      this.editing = '';
    },
      (err: any) => {
        console.log(err);
      }
    );
  }


  updatePosition() {
    let data = {
      "position": this.hoteldata.position,
      "id": this.hoteldata._id
    }

    this.api.sortHotel(data).subscribe((res: any) => {
      console.log("updated", res);
      this.editing = '';
    },
      (err: any) => {
        console.log(err);
      });
  }


  updateMedia(id: any) {
    this.hoteldata.files.indexOf(id).slice(1);
    console.log(this.hoteldata.files);

  }


  editMedia() {
    const dialogRef = this.dailog.open(MediaEditComponent, {
      width: '550px',
      maxHeight: '90vh',
      data: { 'media': this.hotelmedias, type: 'media' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.action == 'update') {
        this.hoteldata.files = result.data;
        this.editHotel();
      }
      else {
      }
    }
    );
  }



  editMenu() {
    const dialogRef = this.dailog.open(MediaEditComponent, {
      width: '550px',
      maxHeight: '90vh',
      data: { 'media': this.hotelmenus, type: 'menu' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.action == 'update') {
        this.hoteldata.menus = result.data;
        this.editHotel();
      }
      else {
      }
    }
    );
  }


  updateCoordinate() {
    const dialogRef = this.dailog.open(MappickerComponent, {
      width: '500px',
      height: '600px',
      disableClose: true,
      data: { type: 'update', latitude: this.hoteldata?.location.coordinates[0], longitude: this.hoteldata?.location.coordinates[1] }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      // this.location = result;
      this.hoteldata.location.coordinates = [result.lat, result.long];
      console.log(this.hoteldata.coordinates);

      this.editHotel();
      // console.log(this.location);
    });
  }



}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  showPost: any = 'pending';
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  Permissionarray$ = new BehaviorSubject([]);
  Permissions: any = [];
  usersectiontab$ = new BehaviorSubject('list');
  constructor(private http: HttpClient, private afAuth: AngularFireAuth, private snackBar: MatSnackBar) {

  }


  Login(token: any) {
    let data = {
      "idToken": token
    }

    return this.http.post(this.baseUrl + 'users/login', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  restePassword(email: any) {
    this.afAuth.sendPasswordResetEmail(email)
      .then(() => {
        // Password reset email sent!
        // ..
        // console.log('Password reset email sent!');
        this.openSnackBar(6, 'Password reset email sent to ' + email, 'ok');
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        this.openSnackBar(6, errorMessage, 'ok');
        // ..
      });
  }

  createFirebaseuser(email: any, password: any) {
    const secondaryApp = firebase.initializeApp(environment.firebaseConfig, 'secondary')
    return secondaryApp.auth().createUserWithEmailAndPassword(email, password).
      then((res) => {
        console.log(res);
        secondaryApp.auth().signOut();
        secondaryApp.delete();
        return res;
      }).catch((err) => {
        console.log(err);
        secondaryApp.auth().signOut();
        secondaryApp.delete();
        return err;
      }
      );

  }



  getPosts(page: any) {
    return this.http.get(this.baseUrl + 'post/get?page=' + page, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  getPostsfilter(page: any, from: any, to: any) {

    return this.http.get(this.baseUrl + 'post/get_filter?page=' + page + '&from=' + from + '&to=' + to, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  getPostsToapprove(page: any) {
    return this.http.get(this.baseUrl + 'post/to_approve?page=' + page, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  getPostsComments(id: any) {
    return this.http.get(this.baseUrl + 'post/get_comment?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  getPostCommentReplay(id: any) {
    return this.http.get(this.baseUrl + 'post/get_reply_comment?id=' + id + '&page=0', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  approvePost(id: any, notify: any) {
    return this.http.post(this.baseUrl + 'post/approveOrreject', {
      "id": id,
      "approve": true,
      "triggerPush": notify
    }, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  unapprovePost(id: any) {
    return this.http.post(this.baseUrl + 'post/approveOrreject', {
      "id": id,
      "approve": false
    }, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  getUsers(page: any) {
    return this.http.get(this.baseUrl + 'users/users/list?page=' + page, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  deleteUser(id: any) {
    let data = {
      'id': id,
    };
    return this.http.get(this.baseUrl + 'users/deleteUser' + data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  blockUser(data: any) {

    return this.http.post(this.baseUrl + 'users/blockUser', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  rolechange(data: any) {

    return this.http.post(this.baseUrl + 'users/role_change', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  getPostbyId(id: any) {
    return this.http.get(this.baseUrl + 'post/get_single_post?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  deletePost(id: any) {
    return this.http.get(this.baseUrl + 'post/delete_post?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),

    });
  }
  getAllBlood() {
    return this.http.get(this.baseUrl + 'blood/get_all_blood', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });


  }

  editBloodbank(data: any) {
    return this.http.post(this.baseUrl + 'blood/blood_bank/edit', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });

  }



  addBloodbank(data: any) {
    return this.http.post(this.baseUrl + 'blood/blood_bank/add', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });

  }


  getAdminUsers() {
    return this.http.get(this.baseUrl + 'users/user/admin_user', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });

  }

  addHotel(data: any) {
    return this.http.post(this.baseUrl + 'hotel/add', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  editHotel(data: any) {
    return this.http.post(this.baseUrl + 'hotel/edit', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  getHotel(page: any, type: any) {
    return this.http.get(this.baseUrl + 'hotel/get_all_hotels?page=' + page + '&type=' + type, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  deleteHotel(id: any) {
    return this.http.get(this.baseUrl + 'hotel/delete_hotel?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  getHotelRating(id: any) {
    return this.http.get(this.baseUrl + 'hotel/ratings?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  sortHotel(data: any) {
    return this.http.post(this.baseUrl + 'hotel/sort_hotel', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }


  uploadContent(data: any) {

    return this.http.post(this.baseUrl + 'post/uploadContent', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  getHotelbyId(id: any) {
    return this.http.get(this.baseUrl + 'hotel/get_hotel_by_id?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }



  getPrmissions() {
    return this.http.get(this.baseUrl + 'users/user/permissions', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  setPermission(data: any) {
    return this.http.post(this.baseUrl + 'users/user/update_permissions', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }


  createAdmin(data: any) {
    return this.http.post(this.baseUrl + 'users/user/create_new', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  verifyuser(data: any) {

    return this.http.post(this.baseUrl + 'users/profile_verification', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }



  openSnackBar(duration: any, msg: any, action: any) {
    this.snackBar.open(msg, action, {
      duration: duration * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  getJobs(page: any) {
    return this.http.get(this.baseUrl + 'job/jobs?perPage=10&page=' + page, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }


  getPendingenquiries() {
    return this.http.get(this.baseUrl + 'hotel/contact_form/pending', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  enquirymarkascompleted(data: any) {
    return this.http.post(this.baseUrl + 'hotel/contact_form/markAsComplete', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });

  }


  userInfo() {
    return this.http.get(this.baseUrl + 'users/me', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }



  toggleComment(data: any) {
    return this.http.post(this.baseUrl + 'post/comment_toggle', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }



  getAdminSettings() {
    return this.http.get(this.baseUrl + 'users/get_admin_settings', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  editAdminSettings(data: any) {
    return this.http.post(this.baseUrl + 'users/edit_admin_settings', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  jobmarkClosed(data: any) {
    return this.http.post(this.baseUrl + 'job/mark_as_closed', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  pinUpin(data: any) {
    return this.http.post(this.baseUrl + 'post/pin_unpin', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  getReportedPosts() {
    return this.http.get(this.baseUrl + 'post/reported_posts', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  reportedPostsingle(id: any) {
    return this.http.post(this.baseUrl + 'post/reported_posts_single', {
      "id": id
    }, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  // reportedPostsingle(data:any){
  //     return this.http.post(this.baseUrl + '/post/reported_posts_single',data,{
  //     headers: new HttpHeaders({
  //       Authorization: 'Bearer ' + localStorage.getItem('token'),
  //     }),
  //   });
  // }

  userSearch(name: any) {
    return this.http.get(this.baseUrl + 'users/search?name=' + name, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }



  getBanner() {
    return this.http.get(this.baseUrl + 'banner/get', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }
  addBanners(data: any) {
    return this.http.post(this.baseUrl + 'banner/add', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });

  }
  editBanner(data: any) {
    return this.http.post(this.baseUrl + 'banner/edit', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });

  }

  deleteBanner(data: any) {
    return this.http.get(this.baseUrl + 'banner/delete?id=' + data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });

  }




  pushForPost(data: any) {
    return this.http.post(this.baseUrl + 'users/push_for_post', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }

  statistics() {
    return this.http.get(this.baseUrl + 'dashboard/statitics', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    });
  }











  // #####################################end##############################################

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from '@angular/material/dialog';
import { CommentsComponent } from './comments/comments.component';
import { ConfirmboxComponent } from 'src/app/confirmbox/confirmbox.component';
import { ThemePalette } from '@angular/material/core';
@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss']
})
export class PostDetailsComponent implements OnInit {
  postId: any;
  data: any;
  baseUrl = environment.baseUrl + 'files/';
  postcontents: any = [];
  color: ThemePalette = 'primary';
  checked = false;
  disabled = false;
  constructor(private route: ActivatedRoute, private router: Router, private api: ApiService, private spinner: NgxSpinnerService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.postId = this.route.snapshot.paramMap.get('id');
    this.getpostData();
  }


  getpostData() {
    this.spinner.show();
    this.api.getPostbyId(this.postId).subscribe((res: any) => {
      console.log(res);
      this.data = res['post'];
      if (this.data?.approved) {
        this.api.showPost = 'all';
      }
      else {
        this.api.showPost = 'pending';
      }



      let ctemp: any = [];
      res?.post?.contents.forEach((ele: any) => {

        if (ele.location == 'LOCAL') {
          if (ele.type == 'IMAGE') {
            ctemp.push(
              {
                location: "LOCAL",
                alt: 'image',
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v,
                image: this.baseUrl + ele.url,
                thumbImage: this.baseUrl + ele.url,


              }
            );
          }
          else {
            ctemp.push(
              {
                location: "LOCAL",
                alt: 'video',
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v,
                video: this.baseUrl + ele.url,
                thumbImage: this.baseUrl + ele.url,


              }
            );
          }
        }
        else {

          if (ele.type == 'IMAGE') {
            ctemp.push(
              {
                location: "S3",
                alt: 'image',
                image: ele.url,
                thumbImage: ele.url,
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v

              }
            );
          }
          else {
            ctemp.push(
              {
                location: "S3",
                alt: 'video',
                video: ele.url,
                thumbImage: ele.url,
                type: ele.type,
                creater: ele.creater,
                _id: ele._id,
                __v: ele.__v

              }
            );
          }

        }

      });
      this.postcontents.push(ctemp);

      console.log(this.postcontents[0]);





      this.spinner.hide();
    },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }


  approvePost() {
    this.spinner.show();
    this.api.approvePost(this.postId, false).subscribe((res: any) => {
      console.log(res);
      this.getpostData();
      this.spinner.hide();
    },
      err => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  approvePostwithNotification() {
    this.spinner.show();
    this.api.approvePost(this.postId, true).subscribe((res: any) => {
      console.log(res);
      this.getpostData();
      this.spinner.hide();
    },
      err => {
        this.spinner.hide();
        console.log(err);
      }
    );

  }



  unapprovePost() {
    this.spinner.show();
    this.api.unapprovePost(this.postId).subscribe((res: any) => {
      console.log(res);
      this.getpostData();
      this.spinner.hide();

    },
      err => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  openComment() {
    const dialogRef = this.dialog.open(CommentsComponent, {
      disableClose: true, width: '500px', maxHeight: '90vh',
      data: { id: this.postId }
    }
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  backtoPosts() {
    this.router.navigate(['/posts']);
  }


  deletePost() {
    const dilogref = this.dialog.open(ConfirmboxComponent, {
      data: {
        tasktype: 'delete',
        btnmsg: 'Delete',
        message: 'Are you sure you want to delete?',
      }
    });
    dilogref.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.api.deletePost(this.postId).subscribe((res: any) => {
          console.log(res);

          this.spinner.hide();
          this.router.navigate(['/posts']);
        },
          err => {
            this.spinner.hide();
            console.log(err);
          }
        );
      }
      else {
        console.log("user cancelled");
      }
    });

  }

  commentToggle(event: any) {
    console.log(event);

    let data;
    if (event.checked) {
      data = {
        "id": this.postId,
        "comment_status": true
      }
    }
    else {
      data = {
        "id": this.postId,
        "comment_status": false
      }
    }

    this.spinner.show();


    this.api.toggleComment(data).subscribe((res: any) => {
      console.log(res);
      this.getpostData();
      this.spinner.hide();
    },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }


  pushForPost() {
    let data = {
      "postId": this.postId
    };
    this.api.pushForPost(data).subscribe((res: any) => {
      console.log(res);
    },
      err => {
        console.log(err);
      }
    );

  }


}

<h3 style="font-weight: 600;">Edit {{data?.type}}</h3>
<div class="d-flex my-4 action">
    <button class="btn btn-orenge" (click)="updatedata()">
        Update
    </button>

    <button class="btn btn-danger" (click)="cancel()">
        close
    </button>
</div>

<div class="container">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> uploading... </p>
    </ngx-spinner>
    <label style=" border: 1px solid rgb(139, 139, 139); padding:5px;width: 100%; color: #000!important;" for="file-upload" class="file-attach">
        <mat-icon aria-hidden="false" class="more-btn" aria-label="Example home icon">upload_file</mat-icon>
        Upload media
   </label>
    <input type="file" id="file-upload" (change)="uploadfile($event)" class="form-control d-none">

</div>

<div class="image-container">
    <div class="image-item" *ngFor="let item of mediadata">
        <button class="btn-delete">
            <mat-icon (click)="removemedia(item)">delete</mat-icon>
        </button>
        <img [src]="item?.image" alt="image" width="150px" height="150px">
    </div>
</div>
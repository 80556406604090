<div class="contianer">

    <mat-form-field appearance="fill">
        <mat-label style="font-weight: 900; margin-bottom: 6px;">Select a date range</mat-label>
        <div class="range-box">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate #startdate placeholder="Start date">
                <input matEndDate #enddate placeholder="End date">
            </mat-date-range-input>

        </div>
    </mat-form-field>


    <button class="btn btn-danger mt-4 mx-2" type="button" (click)="cancel()">cancel</button>

    <button class="btn btn-success mt-4" type="button" [disabled]="!startdate.value||!enddate.value" (click)="close()">Done</button>

</div>
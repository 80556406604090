<div class="container blood-box">

    <div class="typeswitch">
        <div class="type" [ngClass]="{'tab_btn' : activeType == 'BLOOD_BANK','tab_btn1' : activeType == 'BLOOD_DONER'}" (click)="activeType='BLOOD_BANK'">Blood bank</div>
        <div class="type" [ngClass]="{'tab_btn' : activeType == 'BLOOD_DONER','tab_btn1' : activeType == 'BLOOD_BANK'}" (click)="activeType='BLOOD_DONER'">Blood donor</div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>

    <div *ngIf="activeType=='BLOOD_BANK'">

        <button class="btn btn-primary btn-add" (click)="addBloodBank()"><mat-icon aria-label="Side nav toggle icon" class="addicon" >add</mat-icon> Add blood bank</button>
        <div class="nodata" *ngIf="bloodbanklist?.length<1">
            No blood bank available
        </div>
        <table class="table" *ngIf="bloodbanklist?.length>0">
            <thead>
                <tr class="head-table">
                    <th class="head-table-item">Name</th>
                    <th class="head-table-item">Contact</th>
                    <th class="head-table-item">Location</th>
                    <th class="head-table-item">Last updated</th>
                    <th class="head-table-item">Action</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let bloodbank of bloodbanklist">
                    <td>{{bloodbank.blood_bank_name}}</td>
                    <td>{{bloodbank.contact_no}}</td>
                    <td>{{bloodbank.location}}</td>
                    <td>{{bloodbank.updatedAt| date:'short'}}</td>
                    <td>
                        <button class="btn btn-primary" (click)="editBloodbank(bloodbank)">Edit</button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>




    <div *ngIf="activeType=='BLOOD_DONER'">
        <div class="nodata" *ngIf="bloodUser?.length<1">
            No blood doners available
        </div>
        <table class="table" *ngIf="bloodUser?.length>0">
            <thead>
                <tr class="head-table">
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Location</th>
                    <th>Group</th>
                    <th>Gender</th>
                    <th>Last updated</th>

                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let blooddonor of bloodUser">
                    <td>{{blooddonor?.user?.name}}</td>
                    <td>{{blooddonor?.user?.phone_no}}</td>
                    <td>{{blooddonor.location}}</td>
                    <td>{{blooddonor.group}}</td>
                    <td>{{blooddonor.gender}}</td>
                    <td>{{blooddonor.updatedAt| date:'short'}}</td>

                </tr>
            </tbody>
        </table>



    </div>





</div>
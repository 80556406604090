import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { NavigationEnd, Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ApiService } from '../services/api.service';
import { ConfirmboxComponent } from '../confirmbox/confirmbox.component';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @ViewChild('sidenavxx') sidenavxx: any;
  showName: boolean = false;
  access: any;
  currUser: any;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),

      shareReplay()
    );
  currentRoute: any;
  event$;
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private breakpointObserver: BreakpointObserver, private api: ApiService, private router: Router, private auth: AngularFireAuth) {
    this.event$
      = this.router.events
        .subscribe(
          (event: NavigationEvent) => {
            if (event instanceof NavigationStart) {
              console.log(event.url);
              if (event.url == '/posts') {
                this.currentRoute = 'Posts';
              }
              else if (event.url == '/users') {
                this.currentRoute = 'Users';
              }
              else if (event.url == '/bloodbank') {
                this.currentRoute = 'Blood bank';
              }
              else if (event.url == '/settings') {
                this.currentRoute = 'Settings';
              }
              else if (event.url == '/hospitality') {
                this.currentRoute = 'Hospitality';
              }
              else if (event.url == '/enquiry') {
                this.currentRoute = 'Enquiry';
              }
              else if (event.url == '/jobs') {
                this.currentRoute = 'Jobs';
              }
              else if (event.url == '/reporting') {
                this.currentRoute = 'Reported post';
              }
              else if (event.url == '/banner') {
                this.currentRoute = 'Banner';
              }
            }


          });

    this.access = localStorage.getItem('access');

    this.getUserInfo();

  }

  goto(url: string) {
    this.router.navigate([url]);
  }

  close() {
    this.isHandset$.subscribe(isVisible => {
      if (isVisible) {
        this.sidenavxx.close();
      }
      else {
        this.sidenavxx.open();
      }
    });
  }

  handleSignOut() {

    const dialogRef = this.dialog.open(ConfirmboxComponent, {
      data: {
        tasktype: 'delete',
        btnmsg: 'Logout',
        message: 'Are you sure you want to logout?',
      }
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {

        try {
          const res = await this.auth.signOut();
          localStorage.clear();

          this.router.navigateByUrl('/login');

        } catch (error) {

        }
      }
      else {
        console.log('cancel');
      }
    });
  }

  openSnackBar(duration: any, msg: any, action: any) {
    this.snackBar.open(msg, action, {
      duration: duration * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  setBoo() {
    this.showName = !this.showName;
  }

  getUserInfo() {
    this.api.userInfo().subscribe((res: any) => {
      this.currUser = res['data'];
      // console.log("check   :",res);
      this.api.Permissions = res['data'].permissions;
      localStorage.setItem('access', res['data'].permissions);
    },
      err => {
        console.log(err, "login expired");
        this.openSnackBar(5, 'Login expired. Please Login again', 'ok');
        this.auth.signOut();
        localStorage.clear();

        this.router.navigateByUrl('/login');

      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-user-outlet',
  templateUrl: './user-outlet.component.html',
  styleUrls: ['./user-outlet.component.scss']
})
export class UserOutletComponent implements OnInit {
  activeRoute:any=this.api.usersectiontab$.getValue();
  constructor(private router:Router,private api:ApiService) {
  
   }
  
  ngOnInit(): void {

  }


  goto(path:string){
    this.router.navigate(['users/'+path]);
    this.activeRoute = path;
  }
}

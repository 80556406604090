<div class="container outer">
    <h4> {{data?.name}}</h4>


    <div class="container">
        <div class="action">
            <div>Delete user</div>
            <div class="btn-action" (click)="deleteUser()">
                <mat-icon aria-label="Side nav toggle icon">delete</mat-icon>
            </div>
        </div>
        <div class="action">
            <div>Block user</div>
            <div class="btn-action" (click)="blockUser()">
                <mat-icon aria-label="Side nav toggle icon">block</mat-icon>
            </div>
        </div>
    </div>


    <button class="btn btn-danger" (click)="close()">
        Close
    </button>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { MappickerComponent } from './mappicker/mappicker.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar'
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  addtype: any;
  contents: any = [];
  mcontents: any = [];
  menucontents: any = [];
  location: any = { 'lat': 0, 'long': 0 };
  uploadedFiles: any = [];
  coordinates: any = [];
  name: any;
  discription: any;
  locationname: any;
  website: any;
  menu: any='menu';
  open_time: any;
  close_time: any;
  contact: any = '';
  submitted: boolean = false;
  per_day_cost: number = 0;


  constructor(private api: ApiService,private spinner:NgxSpinnerService, private route: ActivatedRoute, private snackBar: MatSnackBar, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.addtype = this.route.snapshot.url[0].path;
    if (this.addtype == 'hotels') {
      this.addtype = 'hotel';
    }
    else if (this.addtype == 'resorts') {
      this.addtype = 'resort';
    }


  }


  uploadContent(event: any, typeselected: any) {
    console.log(event);

    let formData = new FormData();
    let type = '';
    if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png') {
      type = 'IMAGE';
    }
    else if (event.target.files[0].type == 'video/mp4') {
      type = 'VIDEO';
    }
    formData.append('file', event.target.files[0]);
    formData.append('type', type);


    // setTimeout(()=>{console.log(formData),
    //   console.log(event.target.files[0]);
    //   console.log(event.target.files[0].type);


    // },2000);
    this.spinner.show();
    this.api.uploadContent(formData).subscribe((res: any) => {
      console.log("RESPONSE : ", res);
      this.spinner.hide();
      event.target.value = '';
      console.log(this.contents);
      let url = environment.baseUrl + 'files/' + res.data.url;
     

      if (typeselected == 'media') {
        this.contents.push(res.data._id);
        if (type == 'IMAGE') {
          this.uploadedFiles.push({ 'image': url, 'thumbImage': url, 'url': url, 'type': type });
        }
        else if (type == 'VIDEO') {
          this.uploadedFiles.push({ 'video': url, 'thumbImage': url, 'url': url, 'type': type });
        }
        console.log(this.uploadedFiles);
      }
      else if (typeselected == 'menu') {
        this.mcontents.push(res.data._id);
        if (type == 'IMAGE') {
          this.menucontents.push({ 'image': url, 'thumbImage': url, 'url': url, 'type': type });
        }
        else if (type == 'VIDEO') {
          this.menucontents.push({ 'video': url, 'thumbImage': url, 'url': url, 'type': type });
        }
        console.log(this.menucontents);
      }


    },
      (err: any) => {
        console.log(err);
      }

    )
  }


  setCoordinate() {
    const dialogRef = this.dialog.open(MappickerComponent, {
      width: '500px',
      height: '600px',
      disableClose: true,
      data: { latitude: 0, longitude: 0 }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      this.location = result;
      console.log(this.location);
    });
  }

  cancel() {
    this.router.navigate(['/hospitality/' + this.addtype + 's']);
  }

  addhotel() {
    this.submitted = true;
    if (this.name == undefined || this.name == '' || this.contact == undefined || this.contact == '' || this.addtype == undefined || this.addtype == '' || this.discription == undefined || this.discription == '' || this.locationname == undefined || this.locationname == '' || this.open_time == undefined || this.open_time == '' || this.close_time == undefined || this.close_time == '') {

      this.openSnackBar(2, 'Please fill all required fields', 'ok');
      return;
    }
    if (this.addtype == 'resort' && this.per_day_cost == 0) {
      this.openSnackBar(2, 'Please update per day cost', 'ok');
      return;
    }


    if (this.contents.length == 0) {
      this.openSnackBar(3, 'Please upload atleast one media ontent', 'ok');
      return;

    }


    let temp: any = {
      "name": this.name,
      "phone": this.contact,
      "type": this.addtype,
      "description": this.discription,
      "location_name": this.locationname,
      "files": this.contents,
      "website": this.website,
      
      "menus": this.mcontents,
      "open_time": this.open_time,
      "close_time": this.close_time,
      "location": {
        "type": "Point",
        "coordinates": [
          this.location.lat, this.location.long]
      }
    }

    if (this.addtype == 'resort') {
      temp['per_day_cost'] = this.per_day_cost;
    }



    this.api.addHotel(temp).subscribe((res: any) => {
      console.log(res);
      this.openSnackBar(2, 'Hotel added successfully', 'ok');
      this.router.navigate(['/hospitality/' + this.addtype + 's']);

    },
      (err: any) => {
        console.log(err);
        this.openSnackBar(2, 'Something went wrong', 'ok');
      }
    )
    console.log(temp);

  }

  openSnackBar(duration: any, msg: any, action: any) {
    this.snackBar.open(msg, action, {
      duration: duration * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  checkChars(e:any) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			// this.msg = '<span class="msg">OOPs! Only numeric values or digits allowed</span>';
      return false;
		}
    else{
      return true;
    }
   
	}

  checkUrl() {
    let regex=new RegExp("/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)");
   
    if(regex.test(this.website)){
      console.log("true");
      return true;
      
      

    }
    else{
      console.log("false");
      return false;
    } 
  }


  // add hotel

  // {
  //   "id": "61b88b8b49ef5fe72d0372aa",
  //   "name": "test",
  //   "type":"resort",
  //   "description": "test",
  //   "location_name": "Anayara",
  //   "files": [
  //      "61b88b6949ef5fe72d0372a9"
  //   ],
  //   "website": "www.google.com",
  //   "menu": "sample : 100 rs",
  //   "open_time": "10:10:10",
  //   "close_time": "11:11:!1",
  //   "location": {
  //       "type": "Point",
  //       "coordinates": [
  //           122.56667,
  //           111.2313
  //       ]
  //   }
  // }


}

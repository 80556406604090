<div class="container p-3 post">

    <div class="d-flex" style="justify-content: space-between;">
        <button (click)="backtoPosts()" matTooltip="Back to posts" matTooltipPosition="right" class="btn-back"> <span>
                <mat-icon aria-label="Side nav toggle icon">arrow_back</mat-icon>
            </span> </button>

        <div>

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">

                <button mat-menu-item (click)="deletePost()">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>
                <button *ngIf="data?.approved" mat-menu-item (click)="pushForPost()">
                    <mat-icon>notifications_active</mat-icon>
                    Push Notification
                </button>


                <button *ngIf="!data?.approved" mat-menu-item (click)="approvePost()">
                    <mat-icon>done</mat-icon>
                    Approve post
                </button>
                <button *ngIf="!data?.approved" mat-menu-item (click)="approvePostwithNotification()">
                    <mat-icon>notifications_active</mat-icon>
                    Approve post with Notification
                </button>

                <button mat-menu-item>
                    <mat-icon>comments_disabled</mat-icon>
                    Disable comments
                    <mat-slide-toggle class="example-margin" (change)="commentToggle($event)" [color]="color"
                        [checked]="data?.comment_off" [disabled]="disabled">

                    </mat-slide-toggle>
                </button>

            </mat-menu>


        </div>
    </div>

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <div class="user-info">

        <div class="sec1">
            <div class="user-icon">
                {{data?.owner?.name[0]| uppercase}}
            </div>
            <div>
                <div>{{data?.owner?.name}}</div>
                <div style="font-size: 8px;" class="date-string">{{data?.createdAt | date:'short'}}</div>
            </div>
        </div>
        <div class="d-flex">
            <div class="sec2" style="margin-right: 10px;">
                <mat-icon aria-label="Side nav toggle icon">favorite</mat-icon><span style="font-size: 20px;">
                    {{data?.like}}</span>
            </div>
            <div class="sec2" (click)="openComment()" matTooltip="view comments">
                <mat-icon aria-label="Side nav toggle icon">comment</mat-icon><span style="font-size: 20px;">
                    {{data?.comments}}</span>
            </div>
        </div>


        <!-- <div *ngIf="data?.approved" style="color: green; font-weight: 600;">
            <mat-icon aria-label="Side nav toggle icon" class="ico-n">done_outline</mat-icon>&nbsp;Approved
        </div> -->


    </div>

    <div class="postdata" [innerHtml]="data?.title"></div>


    <div class="contents">
        <ng-image-slider [images]="postcontents[0]" #nav></ng-image-slider>





    </div>



</div>
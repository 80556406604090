import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessGuard } from '../guards/access.guard';
import { BannerGuard } from '../guards/banner.guard';
import { BloodGuard } from '../guards/blood.guard';
import { EnquiryGuard } from '../guards/enquiry.guard';
import { HotelGuard } from '../guards/hotel.guard';
import { JobsGuard } from '../guards/jobs.guard';
import { PostGuard } from '../guards/post.guard';
import { ReportingGuard } from '../guards/reporting.guard';
import { SettingsGuard } from '../guards/settings.guard';
import { UsersGuard } from '../guards/users.guard';
import { BannerComponent } from './banner/banner.component';

import { BloodbankComponent } from './bloodbank/bloodbank.component';
import { DashboardComponent } from './dashboard.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { AddComponent } from './hospitality/add/add.component';
import { DetailsViewComponent } from './hospitality/details-view/details-view.component';
import { RatingComponent } from './hospitality/details-view/rating/rating.component';
import { HospitalityComponent } from './hospitality/hospitality.component';
import { HotelsComponent } from './hospitality/hotels/hotels.component';
import { ResortsComponent } from './hospitality/resorts/resorts.component';
import { JobsComponent } from './jobs/jobs.component';
import { OverviewComponent } from './overview/overview.component';
import { PostDetailsComponent } from './posts/post-details/post-details.component';
import { PostsComponent } from './posts/posts.component';
import { ReportingDetailsComponent } from './reporting/reporting-details/reporting-details.component';
import { ReportingListComponent } from './reporting/reporting-list/reporting-list.component';
import { ReportingComponent } from './reporting/reporting.component';
import { AddAdminComponent } from './settings/admin-users/add-admin/add-admin.component';
import { AdminUsersComponent } from './settings/admin-users/admin-users.component';
import { AdvertisementComponent } from './settings/advertisement/advertisement.component';
import { SettingsComponent } from './settings/settings.component';
import { UserOutletComponent } from './users/user-outlet/user-outlet.component';
import { UserSearchComponent } from './users/user-search/user-search.component';
import { UsersComponent } from './users/users.component';



const routes: Routes = [

  {
    path: '', component: DashboardComponent,

    children: [
      { path: 'users', redirectTo: 'users/list' },
      {
        path: 'users', component: UserOutletComponent,
        children: [{ path: 'list', component: UsersComponent },
        { path: 'search', component: UserSearchComponent },

        ],
        canActivate: [UsersGuard],


      },


      { path: 'posts', component: PostsComponent, canActivate: [PostGuard] },
      { path: 'posts/:id', component: PostDetailsComponent, canActivate: [PostGuard] },
      { path: 'bloodbank', component: BloodbankComponent, canActivate: [BloodGuard] },
      {
        path: 'hospitality', component: HospitalityComponent, canActivate: [HotelGuard],
        children: [{ path: 'hotels', component: HotelsComponent },
        { path: 'resorts', component: ResortsComponent },
        { path: 'hotels/details/:id', component: DetailsViewComponent },
        { path: 'hotels/details/:id/rating', component: RatingComponent },
        { path: 'resorts/details/:id', component: DetailsViewComponent },
        { path: 'resorts/details/:id/rating', component: RatingComponent },
        { path: 'hotels/add', component: AddComponent },
        { path: 'resorts/add', component: AddComponent }
        ]
      },
      { path: 'enquiry', component: EnquiryComponent, canActivate: [EnquiryGuard] },
      { path: 'jobs', component: JobsComponent, canActivate: [JobsGuard] },

      {
        path: 'settings', component: SettingsComponent, canActivate: [SettingsGuard],
        children: [{ path: 'users', component: AdminUsersComponent },
        { path: 'users/add', component: AddAdminComponent },
        { path: 'advertisement', component: AdvertisementComponent }]
      },
      {
        path: 'reporting', component: ReportingComponent, canActivate: [ReportingGuard],
        children: [{ path: 'reporting-list', component: ReportingListComponent },
        { path: 'reporting-list/details/:id', component: ReportingDetailsComponent },


        ]
      },
      { path: 'banner', component: BannerComponent, canActivate: [BannerGuard] },
      { path: 'overview', component: OverviewComponent, canActivate: [SettingsGuard] }
    ],

  },
  // {
  //   path: '/users',
  //   redirectTo: 'users/list',
  //   pathMatch: 'full',
  // },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  rating=0;
  hotelRatings:any;
  constructor(private api: ApiService,private router: Router,private route: ActivatedRoute) { }
  
  ngOnInit(): void {
    console.log(this.route.snapshot.params.id);
    
    this.getRating(this.route.snapshot.params.id);
  }

  getRating(id:any){
    this.api.getHotelRating(id).subscribe((res:any)=>{
      console.log(res);
      this.hotelRatings = res['ratings'];
    },
    (err:any)=>{
      console.log(err);
    }
    );
  }
  




}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  activeRoute:any='users';
  constructor(private router:Router,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.router.navigate(['users'], {relativeTo: this.route});
  }

  goto(path:string){
    this.router.navigate(['settings/'+path]);
    this.activeRoute = path;
  }

}

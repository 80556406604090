<div class="container">

    <h3>Edit blood bank</h3>

    <div class="form-group">
        <label class="label" for="location"> Name</label>
        <input type="text" [(ngModel)]="name" class="form-control">
    </div>

    <div class="form-group">
        <label class="label" for="location"> Location</label>
        <input type="text" [(ngModel)]="location" class="form-control">
    </div>

    <div class="form-group">
        <label class="label" for="name"> Contact</label>
        <input type="text" [(ngModel)]="contact" class="form-control">
    </div>



    <div class="form-group">
        <button class="btn btn-primary" (click)="edit()"> Update</button>
    </div>







</div>
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmboxComponent } from 'src/app/confirmbox/confirmbox.component';
import { FilterComponent } from './filter/filter.component';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';


@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  allPosts: any;
  pendingPostList: any;
  showPost: any = 'pending';
  postcontents: any = {};

  userpage: any = 0;
  userpagePending: any = 0;
  noPost: boolean = false;
  noPendingPost: boolean = false;
  baseUrl = environment.baseUrl + 'files/';
  isFilter: boolean = false;
  filterStart: any;
  filterEnd: any;
  constructor(private api: ApiService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.showPost = this.api.showPost;
    this.loadPosts();
    this.getpendingPosts();
  }

  loadPosts() {
    this.noPost = false;
    this.spinner.show();

    // filter post starts here
    if (this.isFilter) {

      this.api.getPostsfilter(this.userpage, this.filterStart, this.filterEnd).subscribe((res: any) => {

        this.spinner.hide();
        console.log(res);
        res.post.forEach((element: any) => {
          if (element.contents) {
            let ctemp: any = [];
            element.contents.forEach((ele: any) => {
              if (ele.location == 'LOCAL') {
                if (ele.type == 'IMAGE') {
                  ctemp.push(
                    {
                      location: "LOCAL",
                      alt: 'image',
                      type: ele.type,
                      creater: ele.creater,
                      _id: ele._id,
                      __v: ele.__v,
                      image: this.baseUrl + ele.url,
                      thumbImage: this.baseUrl + ele.url,
                    }
                  );
                }
                else {
                  ctemp.push(
                    {
                      location: "LOCAL",
                      alt: 'video',
                      type: ele.type,
                      creater: ele.creater,
                      _id: ele._id,
                      __v: ele.__v,
                      video: this.baseUrl + ele.url,
                      thumbImage: this.baseUrl + ele.url,
                    }
                  );
                }
              }
              else {
                if (ele.type == 'IMAGE') {
                  ctemp.push(
                    {
                      location: "S3",
                      alt: 'image',
                      image: ele.url,
                      thumbImage: ele.url,
                      type: ele.type,
                      creater: ele.creater,
                      _id: ele._id,
                      __v: ele.__v
                    }
                  );
                }
                else {
                  ctemp.push(
                    {
                      location: "S3",
                      alt: 'video',
                      video: ele.url,
                      thumbImage: ele.url,
                      type: ele.type,
                      creater: ele.creater,
                      _id: ele._id,
                      __v: ele.__v
                    }
                  );
                }
              }
            });
            this.postcontents[element._id] = ctemp;
          }
        });
        console.log("created array ", this.postcontents);
        if (res['page'] > 0) {
          this.allPosts = res;
        }
        else {
          this.userpage = 0;
          this.api.getPosts(this.userpage).subscribe((res: any) => {
            this.allPosts = res;
            if (res['page'] == -1) {
              this.noPost = true;
            }
          });
        }


      }, err => {
        this.spinner.hide();
        console.log(err);
      }
      );

    }
    // normal postlisting start here
    else {
      this.api.getPosts(this.userpage).subscribe((res: any) => {
        this.spinner.hide();
        console.log(res);
        res.post.forEach((element: any) => {
          if (element.contents) {
            let ctemp: any = [];
            element.contents.forEach((ele: any) => {
              if (ele.location == 'LOCAL') {
                if (ele.type == 'IMAGE') {
                  ctemp.push(
                    {
                      location: "LOCAL",
                      alt: 'image',
                      type: ele.type,
                      creater: ele.creater,
                      _id: ele._id,
                      __v: ele.__v,
                      image: this.baseUrl + ele.url,
                      thumbImage: this.baseUrl + ele.url,
                    }
                  );
                }
                else {
                  ctemp.push(
                    {
                      location: "LOCAL",
                      alt: 'video',
                      type: ele.type,
                      creater: ele.creater,
                      _id: ele._id,
                      __v: ele.__v,
                      video: this.baseUrl + ele.url,
                      thumbImage: this.baseUrl + ele.url,
                    }
                  );
                }
              }
              else {
                if (ele.type == 'IMAGE') {
                  ctemp.push(
                    {
                      location: "S3",
                      alt: 'image',
                      image: ele.url,
                      thumbImage: ele.url,
                      type: ele.type,
                      creater: ele.creater,
                      _id: ele._id,
                      __v: ele.__v
                    }
                  );
                }
                else {
                  ctemp.push(
                    {
                      location: "S3",
                      alt: 'video',
                      video: ele.url,
                      thumbImage: ele.url,
                      type: ele.type,
                      creater: ele.creater,
                      _id: ele._id,
                      __v: ele.__v
                    }
                  );
                }
              }
            });
            this.postcontents[element._id] = ctemp;
          }
        });
        console.log("created array ", this.postcontents);
        if (res['page'] > 0) {
          this.allPosts = res;
        }
        else {
          this.userpage = 0;
          this.api.getPosts(this.userpage).subscribe((res: any) => {
            this.allPosts = res;
            if (res['page'] == -1) {
              this.noPost = true;
            }
          });
        }
      }, err => {
        this.spinner.hide();
        console.log(err);
      }
      );
    }

  }


  getPostbyfilter() {
    this.spinner.show();
    this.api.getPostsfilter(0, 0, 0).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res);
      this.allPosts = res;
    }, err => {
      this.spinner.hide();
      console.log(err);
    }
    );
  }

  getpendingPosts() {
    this.noPendingPost = false;
    this.spinner.show();
    this.api.getPostsToapprove(this.userpagePending).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res);

      res.post.forEach((element: any) => {
        if (element.contents) {
          let ctemp: any = [];
          element.contents.forEach((ele: any) => {

            if (ele.location == 'LOCAL') {
              if (ele.type == 'IMAGE') {
                ctemp.push(
                  {
                    location: "LOCAL",
                    alt: 'image',
                    type: ele.type,
                    creater: ele.creater,
                    _id: ele._id,
                    __v: ele.__v,
                    image: this.baseUrl + ele.url,
                    thumbImage: this.baseUrl + ele.url,


                  }
                );
              }
              else {
                ctemp.push(
                  {
                    location: "LOCAL",
                    alt: 'video',
                    type: ele.type,
                    creater: ele.creater,
                    _id: ele._id,
                    __v: ele.__v,
                    video: this.baseUrl + ele.url,
                    thumbImage: this.baseUrl + ele.url,


                  }
                );
              }
            }
            else {

              if (ele.type == 'IMAGE') {
                ctemp.push(
                  {
                    location: "S3",
                    alt: 'image',
                    image: this.baseUrl + ele.url,
                    thumbImage: this.baseUrl + ele.url,
                    type: ele.type,
                    creater: ele.creater,
                    _id: ele._id,
                    __v: ele.__v

                  }
                );
              }
              else {
                ctemp.push(
                  {
                    location: "S3",
                    alt: 'video',
                    video: this.baseUrl + ele.url,
                    thumbImage: this.baseUrl + ele.url,
                    type: ele.type,
                    creater: ele.creater,
                    _id: ele._id,
                    __v: ele.__v

                  }
                );
              }

            }

          });
          this.postcontents[element._id] = ctemp;
        }
      });


      console.log("created array ", this.postcontents);



      if (res['page'] > 0) {
        this.pendingPostList = res;
      }
      else {
        this.userpagePending = 0;

        this.api.getPostsToapprove(this.userpagePending).subscribe((res: any) => {
          this.pendingPostList = res;
          if (res['page'] == -1) {
            this.noPendingPost = true;
          }
        });
      }

    });
  }




  approvePost(id: any) {
    this.spinner.show();
    this.api.approvePost(id, false).subscribe((res: any) => {
      console.log(res);
      this.getpendingPosts();
      this.spinner.hide();
      this.openSnackBar(3, 'Post Approved', 'Ok');
    },
      err => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  approvePostwithNotification(id: any) {
    this.spinner.show();
    this.api.approvePost(id, true).subscribe((res: any) => {
      console.log(res);
      this.getpendingPosts();
      this.spinner.hide();
      this.openSnackBar(3, 'Post Approved', 'Ok');
    },
      err => {
        this.spinner.hide();
        console.log(err);
      }
    );

  }








  approvePostOld(id: any) {

    const dilogref = this.dialog.open(ConfirmboxComponent, {
      disableClose: true,
      data: {
        tasktype: 'notification',
        btnmsg: 'Send',
        btncancel: 'Don\'t send',
        message: 'Do you want to send notification for this post?',
      }
    });
    dilogref.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.api.approvePost(id, true).subscribe((res: any) => {
          console.log(res);
          this.getpendingPosts();
          this.spinner.hide();
          this.openSnackBar(3, 'Post Approved', 'Ok');
        },
          err => {
            this.spinner.hide();
            console.log(err);
          }
        );
      }
      else {
        console.log("user cancelled");
        this.spinner.show();
        this.api.approvePost(id, false).subscribe((res: any) => {
          console.log(res);
          this.getpendingPosts();
          this.spinner.hide();
          this.openSnackBar(3, 'Post Approved', 'Ok');
        },
          err => {
            this.spinner.hide();
            console.log(err);
          }
        );
      }
    });


  }

  postDetails(id: any) {
    this.router.navigate(['/posts', id]);
  }

  gotoPage(to: any) {
    if (to == 'next') {
      if (this.showPost == 'all') {
        this.userpage++;
        this.loadPosts();
      }
      else {
        this.userpagePending++;
        this.getpendingPosts();
      }

    }
    else {
      if (this.showPost == 'all') {
        this.userpage--;
        this.loadPosts();
      }
      else {
        this.userpagePending--;
        this.getpendingPosts();
      }
    }


  }



  unapprovePost(id: any) {
    this.spinner.show();
    this.api.unapprovePost(id).subscribe((res: any) => {
      console.log(res);
      this.loadPosts();
      this.spinner.hide();

    },
      err => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }


  deletePost(id: any) {
    const dilogref = this.dialog.open(ConfirmboxComponent, {
      data: {
        tasktype: 'delete',
        btnmsg: 'Delete',
        message: 'Are you sure you want to delete?',
      }
    });
    dilogref.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.api.deletePost(id).subscribe((res: any) => {
          console.log(res);
          this.loadPosts();
          this.spinner.hide();
        },
          err => {
            this.spinner.hide();
            console.log(err);
          }
        );
      }
      else {
        console.log("user cancelled");
      }
    });

  }


  filterPost() {
    if (!this.isFilter) {
      this.isFilter = true;
      const dialogref = this.dialog.open(FilterComponent, {
        maxWidth: '500px',
        disableClose: true,

      });

      dialogref.afterClosed().subscribe((result: any) => {
        console.log(result);
        if (result) {
          this.filterStart = result.startdate;
          this.filterEnd = result.enddate;
          this.loadPosts();
        } else {
          this.isFilter = false;
        }
      },
        err => {
          this.spinner.hide();
          console.log(err);
        }
      );
    }
    else {
      this.isFilter = false;
      this.filterStart = '';
      this.filterEnd = '';
      this.loadPosts();
    }
  }

  clearFilter() {
    this.isFilter = false;
    this.filterStart = '';
    this.filterEnd = '';
    this.loadPosts();
  }


  commentToggle(id: any, status: any) {

    let data;
    if (status) {
      data = {
        "id": id,
        "comment_status": false
      }
    }
    else {
      data = {
        "id": id,
        "comment_status": true
      }
    }
    this.spinner.show();
    this.api.toggleComment(data).subscribe((res: any) => {
      console.log(res);
      this.loadPosts();
      this.spinner.hide();
    },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  pinUpin(id: any, state: any) {
    let data: any = {
      post: id,
    }
    if (state) {
      data['pin'] = false;
    }
    else {
      data['pin'] = true;
    }

    this.spinner.show();


    this.api.pinUpin(data).subscribe((res: any) => {
      console.log(res);
      this.spinner.hide();

      this.loadPosts();

    },

      err => {
        console.log(err);
        this.spinner.hide();
      }
    );




  }











  openSnackBar(duration: any, msg: any, action: any) {
    this.snackBar.open(msg, action, {
      duration: duration * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }



  pushForPost(id: any) {
    let data = {
      "postId": id
    };
    this.api.pushForPost(data).subscribe((res: any) => {
      console.log(res);
      this.openSnackBar(3, 'Post Notication send', 'Ok');
    },
      err => {
        console.log(err);
        this.openSnackBar(3, 'Something wend wrong!!', 'Ok');
      }
    );

  }


}



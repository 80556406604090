<div class="container">
    <div class="add-block">
        <button class="btn btn-primary" style="font-size: 12px;" routerLink="add">Add new admin</button>
    </div>
    <div class="table-box">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="true">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
        <div class="nodata" *ngIf="adminUser?.length<1">
            No Admin Users
        </div>

        <table class="table" *ngIf="adminUser?.length>0">
            <thead>
                <tr class="head-table">
                    <th class="head-table-item">Name</th>
                    <th class="head-table-item">Email</th>
                    <th class="head-table-item">Phone</th>
                    <th class="head-table-item">Role</th>
                    <th class="head-table-item">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of adminUser">
                    <td>{{user.name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.phone_no}}</td>
                    <td>{{user.role}}</td>
                    <td style="display: flex; justify-content: space-between;">
                        <button class="btn btn-mail" (click)="resetPassword(user.email)">
                            <mat-icon aria-label="Side nav toggle icon">mail</mat-icon>
                             Reset mail
                        </button>
                        <button class="btn btn-danger" (click)="editUser(user)">
                           <mat-icon aria-label="Side nav toggle icon">lock</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


</div>
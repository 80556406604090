import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AddbannerComponent } from './addbanner/addbanner.component';
import { EditbannerComponent } from './editbanner/editbanner.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  bannerList: any ;
  pagelist: any;
  userpage: any = 0;
  baseUrl = environment.baseUrl+'files/';
  banner:any=[];
  postcontents: any;
  noBanner: boolean = false;

  constructor(private api:ApiService,private dailog:MatDialog,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getbanner();
  }
  getbanner(){
    this.spinner.show();
    this.api.getBanner().subscribe((res:any)=>{
      this.spinner.hide();
      console.log(res);
      this.bannerList = res['data'];

     


    }, err => {
      this.spinner.hide();
      console.log(err);
    }
    );

  }


  editBanner(item:any){
    
    const dialogRef = this.dailog.open(EditbannerComponent, {
      width: '500px',
      data:item
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log('The dialog was closed');
      if(result){
      this.getbanner();
      }
    });


  }
  addBanner(){
    const dialogRef = this.dailog.open(AddbannerComponent, {
      width: '500px',

    });

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log('The dialog was closed');
      if(result){
      this.getbanner();
      }
    });
  }



  deleteBanner(id:any){
    this.api.deleteBanner(id).subscribe((res:any)=>{
      console.log(res);
      
      this.getbanner();
    }, err => {
      console.log(err);
    }
    );
  }


}

<div class="container rating-body">
    <div style="font-weight: 800; color: white; display: flex; align-items: center;">
        <button class="btn back-btn" routerLink="../../.">
            <mat-icon aria-label="Side nav toggle icon">arrow_back_ios</mat-icon>

             Back</button>
    </div>
    <div class="nodata" *ngIf="hotelRatings?.length<1">
        No Rating
    </div>

    <div class="rating-box" *ngFor="let item of hotelRatings">
        <div style="font-weight:bold;color: #000;">{{item?.user?.name}}</div>
        <div class="rating-star d-flex">
            <div class="d-flex" *ngFor="let item of  [].constructor(item?.rating)">
                <span style=color:orange;><mat-icon aria-label="Side nav toggle icon">star</mat-icon></span>
            </div>
            <div class="d-flex " *ngFor="let item of  [].constructor(5-item?.rating)">
                <mat-icon aria-label="Side nav toggle icon ">star_border</mat-icon>
            </div>
        </div>
        <div style="font-weight:bold;color: #000;">{{item?.comment}}</div>
        <div style="font-size:x-small;color: #000;">{{item?.createdAt| date:'medium'}}</div>

    </div>

</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmbox',
  templateUrl: './confirmbox.component.html',
  styleUrls: ['./confirmbox.component.scss']
})
export class ConfirmboxComponent implements OnInit {

  constructor(private ref: MatDialogRef<ConfirmboxComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

  }


  Action(tasktype: any, data: any) {

    switch (tasktype) {
      case 'logout':
        this.ref.close(true);
        break;
      case 'delete':
        this.ref.close(true);
        break;
      case 'notification':
        this.ref.close(true);
        break;
      default:
        break;
    }

  }


  cancel() {
    this.ref.close(false);
  }




}

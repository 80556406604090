import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmboxComponent } from 'src/app/confirmbox/confirmbox.component';
import { ApiService } from 'src/app/services/api.service';
import { HotelActionsComponent } from './hotel-actions/hotel-actions.component';


@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent implements OnInit {
  [x: string]: any;

  constructor(private api:ApiService,private spinner: NgxSpinnerService,private dialog:MatDialog) { }
  allHotels:any;
  userpage:any=0;
  ngOnInit(): void {
    this.getHotels(0);
  }


  getHotels(page:number){
    this.spinner.show();
    this.api.getHotel(page,'hotel').subscribe((res:any)=>{
      console.log(res);
      this.allHotels = res['data'];
      this.spinner.hide();
      
      if(res['page']==-1){
        if(this.userpage>0){
          this.userpage--;
          this.getHotels(this.userpage);
        }
      }

    },
    (err:any)=>{
      console.log(err);
      this.spinner.hide();
    }
    
    )
  }


  gotoPage(to:any){
    if(to=='next'){
      this.userpage++;
    }
    else{
      this.userpage--;
    }

    this.getHotels(this.userpage);
  }

  deleteHotel(id: any) {

    this.dialog.open(ConfirmboxComponent, {
      data: {
        tasktype: 'delete',
        btnmsg: 'Delete',
        message: 'Are you sure you want to delete Hotel?',
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
  
    this.spinner.show();
    this.api.deleteHotel(id).subscribe((data: any) => {
      console.log(data);
      this.spinner.hide();
      this.api.openSnackBar(3,"Hotel deleted successfully", "ok");
      this.getHotels(this.userpage)
      
      // this.ref.close();
    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
      this.api.openSnackBar(3,"Something went wrong !!", "ok");
    }
    );
  }
  else {
    this.spinner.hide();
  }

    });
}
actionBox(user: any) {
  const dialogRef = this.dialog.open(HotelActionsComponent, {
    disableClose: true,
    data: user
  }
  );

  dialogRef.afterClosed().subscribe((result: any) => {
    console.log(`Dialog result: ${result}`);
  });
}





}

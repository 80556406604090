import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-media-edit',
  templateUrl: './media-edit.component.html',
  styleUrls: ['./media-edit.component.scss']
})
export class MediaEditComponent implements OnInit {
  mediadata:any=this.data?.media;
  constructor(private api:ApiService,private spinner:NgxSpinnerService, private ref:MatDialogRef<MediaEditComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
console.log(this.mediadata);
  }

  removemedia(media:any){
    this.mediadata.splice(this.mediadata.indexOf(media),1);
  }
updatedata(){
  this.ref.close({data:this.mediadata,action:'update'});
}

cancel(){
  this.ref.close({action:'close'});
}

update(){
  this.ref.close(this.mediadata);
}
  


uploadfile(event:any){
  
  let formData = new FormData();
  let type = '';
  if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png') {
    type = 'IMAGE';
  }
  else if (event.target.files[0].type == 'video/mp4') {
    type = 'VIDEO';
  }

  console.log(event);
  
 this.spinner.show();

  formData.append('file', event.target.files[0]);
  formData.append('type', type);

  this.api.uploadContent(formData).subscribe((res: any) => {
    console.log("RESPONSE : ", res);
    this.mediadata.push({
      location: "LOCAL",
      alt:'image',
      type:type,
      image:environment.baseUrl+'files/'+res.data.url,
      thumbImage: environment.baseUrl +'files/'+res.data.thumbnail,
      // url:environment.baseUrl +'files/'+ res.data.url,
      _id: res.data._id
    });
    this.spinner.hide();

    event.target.value = '';
    this.spinner.hide();
    event.target.value = '';
    
    console.log("media data :",this.mediadata);
    

  },
    (err: any) => {
      console.log(err);
    }

  )
}

}

<div class="container">

    <h3 style="font-weight: 600;">Edit banner</h3>

    <div class="form-group">
        <label class="label" for="title"> Name</label>
        <input type="text" [ngClass]="{'is-invalid': !title&&submitted}" class="form-control" [(ngModel)]="title">
        <div class="invalid-feedback">
            Please enter title
        </div>
    </div>

    <div class="form-group" style="position: relative;">
        <label class="label" for="banner"> Banner Image</label><br>
        <img [src]="baseUrl+banner?.url" width="250px" height="250px" *ngIf="banner?.location=='LOCAL'&&banner!=null" alt="">
        <img [src]="banner?.url" width="250px" height="250px" *ngIf="banner?.location=='S3'&& banner!=null" alt="">
        <button class="btn btn-remove" *ngIf="banner!=null" (click)="cancel()">Replace </button>
    </div>
    <div *ngIf="banner==null" class="filebox">

        <label style=" border: 1px solid rgb(155, 155, 155); padding:5px;width: 100%;color: black!important;" for="file-upload" class="file-attach" [ngClass]="{'invalid-label': uploadedFiles.length==0&&submitted}">
             <mat-icon aria-hidden="false" class="more-btn" aria-label="Example home icon">upload_file</mat-icon>
             Upload banner
        </label>
        <input type="file" id="file-upload" (change)="uploadContent($event,'media')" class="form-control d-none">

    </div>


    <div class="form-group">
        <label class="label" for="enabled"> Status</label>


        <select [(ngModel)]="enabled" class="form-control">
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
            
        </select>


    </div>



    <div class="form-group">
        <button class="btn btn-add" (click)="editbanner()"> Update</button>
        <button class="btn btn-danger" (click)="close()"> cancel</button>
    </div>







</div>
<div class="container">

    <div style="font-weight: 800; color: white; display: flex; align-items: center;">
        <span (click)="back()" style="cursor: pointer;">
            <mat-icon>arrow_back</mat-icon>
        </span>
        <h2 class="text-center" style="width: 100%;padding-top: 18px;">
            Create new admin user
        </h2>
    </div>

    <div class="form-group">
        <label for="name">Name</label>
        <input type="text" [ngClass]="{'is-invalid': !name&&submitted}" class="form-control" [(ngModel)]="name">
    </div>

    <div class="form-group">
        <label for="name">Email</label>
        <input type="text" [ngClass]="{'is-invalid': !email&&submitted}" class="form-control" [(ngModel)]="email">
    </div>

    <div class="form-group">
        <label for="name">Phone</label>
        <input type="number" [ngClass]="{'is-invalid': !phone_no&&submitted}" max="10" min="10" class="form-control" [(ngModel)]="phone_no">
    </div>

    <div class="form-group">
        <label for="name">Address</label>
        <input type="text" class="form-control" [ngClass]="{'is-invalid': !address&&submitted}" [(ngModel)]="address">
    </div>


    <div class="form-group">
        <label for="name">Password</label>
        <input type="text" class="form-control" [ngClass]="{'is-invalid': !password&&submitted}" [(ngModel)]="password">


    </div>

    <button class="btn btn-primary" (click)="createAdmin()">Create</button>

</div>
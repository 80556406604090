import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-hotel-actions',
  templateUrl: './hotel-actions.component.html',
  styleUrls: ['./hotel-actions.component.scss']
})
export class HotelActionsComponent implements OnInit {

  constructor(private ref:MatDialogRef<HotelActionsComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private api:ApiService) { }

  ngOnInit(): void {
    console.log(this.data);
  }
  close(){
    this.ref.close();
  }

  deleteHotel(){
    if(confirm("Are you sure you want to delete this Hotel?")){
   
    this.api.deleteHotel(this.data.id).subscribe((data:any)=>{
      console.log(data);
      
      // this.ref.close();
    },(err:any)=>{
      console.log(err);
    }
    );
  }
  else{

  }

  }

}

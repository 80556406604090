import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-reporting-list',
  templateUrl: './reporting-list.component.html',
  styleUrls: ['./reporting-list.component.scss']
})
export class ReportingListComponent implements OnInit {
  userpage:any=0;
  allReporting: any;
  pagelist: any = [1];
  constructor(private api:ApiService,private spinner: NgxSpinnerService,private dialog:MatDialog) { }

  ngOnInit(): void {
    this.getReports(0);
  }
  getReports(page:number){
    this.spinner.show();
    this.api.getReportedPosts().subscribe((res:any)=>{
      console.log(res);
      this.allReporting = res['data'];
      this.spinner.hide();
      
      if(res['page']==-1){
        if(this.userpage>0){
          this.userpage--;
          this.getReports(this.userpage);
        }
      }

    },
    (err:any)=>{
      console.log(err);
      this.spinner.hide();
    }
    
    )
  }
  


  gotoPage(to:any){
    if(to=='next'){
      this.userpage++;
    }
    else{
      this.userpage--;
    }

    this.getReports(this.userpage);
  }

}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmboxComponent } from 'src/app/confirmbox/confirmbox.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {
  name:any='';
  userList:any;
  
  constructor(private api:ApiService,private spinner: NgxSpinnerService,private dialog: MatDialog) { 
    
  }

  ngOnInit(): void {
    this.api.usersectiontab$.next('search');
  }

  userSearch(){

    this.api.userSearch(this.name).subscribe(
      (data:any)=>{
        console.log(data);
        this.userList=data['data'];
      },
      (error)=>{
        console.log(error);
      }
    )


  }



  verifyUser(id: any,sts:any,name:any) {
    let status=sts==true?false:true;
     let data = {
       "user_id": id,
       "verification": status
     };
     this.spinner.show();
     this.api.verifyuser(data).subscribe((data: any) => {
       console.log(data);
       if(status==true){
       this.api.openSnackBar(3,"User "+name+" verified successfully", "X");
       }
       else{
         this.api.openSnackBar(3,"User "+name+" verification removed", "X");
       }
       this.spinner.hide();
     
     },
       err => {
         console.log(err);
         this.spinner.hide();
         this.api.openSnackBar(3,"Something went wrong !!", "X");
       }
     );
   }
 
   
  changeRole(id: any,sts:any) {
    this.spinner.show();
   let role=sts=='admin'?'user':'admin';
    let data = {
      "id": id,
      "role": role
    };
    this.api.rolechange(data).subscribe((data: any) => {
      console.log(data);
      this.api.openSnackBar(3,"Role changed successfully", "ok");
      this.spinner.hide();
     
    },
      err => {
        console.log(err);
      }
    );
  }


  deleteUser(id: any) {

    this.dialog.open(ConfirmboxComponent, {
      data: {
        tasktype: 'delete',
        btnmsg: 'Delete',
        message: 'Are you sure you want to delete user?',
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
  
    this.spinner.show();
    this.api.deleteUser(id).subscribe((data: any) => {
      console.log(data);
      this.spinner.hide();
      this.api.openSnackBar(3,"User deleted successfully", "ok");
      
      // this.ref.close();
    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
      this.api.openSnackBar(3,"Something went wrong !!", "ok");
    }
    );
  }
  else {
    this.spinner.hide();
  }

    });
}


blockUser(id: any,sts:any) {
  let  status=sts==true?false:true;
   let data= {
     'id':id,
     "blocked":status
   };
   this.spinner.show();
   let msg = status == true ? "Are you sure you want to block this user?" : "Are you sure you want to unblock this user?";
   this.dialog.open(ConfirmboxComponent, {
     data: {
       tasktype: 'delete',
       btnmsg: 'Continue',
       message: msg,
     }
   }).afterClosed().subscribe((result: any) => {
     if (result) {
 
     this.api.blockUser(data).subscribe((data: any) => {
       console.log(data);
       this.spinner.hide();
       if(status==true){
         this.api.openSnackBar(3,"User blocked successfully", "ok");
       }
       else{
         this.api.openSnackBar(3,"User unblocked successfully", "ok");
       }
       
 
       // this.ref.close();
     }, (err: any) => {
       console.log(err);
       this.spinner.hide();
       this.api.openSnackBar(3,"Something went wrong !!", "ok");
     }
     );
   }
   else {
     this.spinner.hide();
   }
 });
 }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { UserAccessComponent } from './user-access/user-access.component';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  constructor(private api:ApiService,private matDialog: MatDialog,private spinner: NgxSpinnerService) { }
  adminUser:any;
  ngOnInit(): void {
    this.getAdmins();
  }


  getAdmins(){
    this.spinner.show();
    this.api.getAdminUsers().subscribe((res:any)=>{
      console.log(res);
      this.adminUser = res['data'];
      this.spinner.hide();
    },
    (err:any)=>{
      console.log(err);
      this.spinner.hide();
    });
  }

  editUser(user:any){
    this.matDialog.open(UserAccessComponent,{
      data:user,
      width:'500px',
     
      
    });
    this.matDialog.afterAllClosed.subscribe(()=>{
      this.getAdmins();
      this.getUserInfo();
    });
  }

  deleteUser(user:any){

  }

  resetPassword(email:any){
    this.api.restePassword(email);
  }


  getUserInfo(){
    this.api.userInfo().subscribe((res:any)=>{
  
      console.log("check   :",res);
      this.api.Permissions=res['data'].permissions;
      localStorage.setItem('access',res['data'].permissions);
    },
    err=>{
      console.log(err);
    }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmboxComponent } from 'src/app/confirmbox/confirmbox.component';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { CommentsComponent } from '../../posts/post-details/comments/comments.component';

@Component({
  selector: 'app-reporting-details',
  templateUrl: './reporting-details.component.html',
  styleUrls: ['./reporting-details.component.scss']
})
export class ReportingDetailsComponent implements OnInit {
  reportId:any;
data:any;
userpage:any=0;
  allReporting: any;
  pagelist: any = [1];
  baseUrl = environment.baseUrl+'files/';
  postcontents:any=[];
  reportReasons:any;
  reportedUsers:any;
  reportedPost:any;
  postId:any;
  activeType:any='POST';
  constructor(private route:ActivatedRoute,private router:Router,private api:ApiService,private spinner:NgxSpinnerService,private dialog:MatDialog) { }

  ngOnInit(): void {
    this.reportId = this.route.snapshot.paramMap.get('id');
     this.getreportingdetail(this.reportId);
  }

  getreportingdetail(id: any) {
    this.spinner.show();
    this.api.reportedPostsingle(id).subscribe((res: any) => {
      console.log(res);
      this.reportReasons = res['data']['reaons'];
      this.reportedUsers=res['data']['reportedUsers'];
      this.postId=res['data']['reportedPost']['_id'];
      this.getpostData();
      this.spinner.hide();
        console.log("#### :",this.reportReasons);
        console.log("#### :",this.reportedUsers);
       

    },
      err => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }


  getpostData(){
    this.spinner.show();
    this.api.getPostbyId(this.postId).subscribe((res:any)=>{
      console.log(res);
      this.spinner.hide();
      this.reportedPost = res['post'];
      console.log("#### :",this.reportedPost);

      
       
          let ctemp:any=[];
          res?.post?.contents.forEach((ele:any) => {
            
            if(ele.location=='LOCAL'){
              if(ele.type=='IMAGE'){
              ctemp.push(
                {
                  location: "LOCAL",
                  alt:'image',
                  type:ele.type,
                  creater:ele.creater,
                  _id:ele._id,
                  __v:ele.__v,
                  image: this.baseUrl+ele.url,
                  thumbImage: this.baseUrl+ele.url,
                 

                }
              );
              }
              else{
                ctemp.push(
                  {
                    location: "LOCAL",
                    alt:'video',
                    type:ele.type,
                    creater:ele.creater,
                    _id:ele._id,
                    __v:ele.__v,
                    video: this.baseUrl+ele.url,
                    thumbImage: this.baseUrl+ele.url,
                   

                  }
                );
              }
            }
            else{

              if(ele.type=='IMAGE'){
              ctemp.push(
                {
                  location: "S3",
                  alt:'image',
                  image:ele.url,
                  thumbImage: ele.url,
                  type:ele.type,
                  creater:ele.creater,
                  _id:ele._id,
                  __v:ele.__v

                }
              );
              }
              else{
                ctemp.push(
                  {
                    location: "S3",
                    alt:'video',
                    video: ele.url,
                    thumbImage: ele.url,
                    type:ele.type,
                    creater:ele.creater,
                    _id:ele._id,
                    __v:ele.__v

                  }
                );
              }

            }

          });
          this.postcontents.push(ctemp);
        
          console.log(this.postcontents[0]);
          
     



      this.spinner.hide();
    },
    err=>{
      console.log(err);
      this.spinner.hide();
    }
    );
  }

  // getReports(page:number){
  //   this.spinner.show();
  //   this.api.getReportedPosts().subscribe((res:any)=>{
  //     console.log(res);
  //     this.allReporting = res['data'];
  //     this.spinner.hide();
      
  //     if(res['page']==-1){
  //       if(this.userpage>0){
  //         this.userpage--;
  //         this.getReports(this.userpage);
  //       }
  //     }

  //   },
  //   (err:any)=>{
  //     console.log(err);
  //     this.spinner.hide();
  //   }
    
  //   )
  // }
  
 
  deletePost(){
    const dilogref= this.dialog.open(ConfirmboxComponent, {
     data: {
       tasktype: 'delete',
       btnmsg: 'Delete',
       message: 'Are you sure you want to delete?',
     }
   });
   dilogref.afterClosed().subscribe((result:any) => {
     if (result) {
     this.spinner.show();
     this.api.deletePost(this.reportId).subscribe((res:any)=>{
       console.log(res);
       
       this.spinner.hide();
     },
     err=>{
       this.spinner.hide();
       console.log(err);
     }
     );
   }
   else{
     console.log("user cancelled");
   }
   });
 
   }
   openComment() {
    const dialogRef = this.dialog.open(CommentsComponent,{disableClose:true,width:'500px',maxHeight:'90vh',
      data:{id:this.postId}
    }
    );

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

}

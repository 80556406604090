<div class="container" style="padding:15px">
    <div class="row mx-2 my-4">
        <div class="col-md-3">
            <div class="card">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div class="icon-sec">
                        <mat-icon class="card-icon">add_circle</mat-icon>
                    </div>
                    <div>
                        <h5 class="card-title">New Posts</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{stats?.newPosts}}
                            <div class="spinner-border text-warning" *ngIf="!stats" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </h6>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-body  d-flex justify-content-between align-items-center">
                    <div class="icon-sec">
                        <mat-icon class="card-icon">favorite</mat-icon>
                    </div>
                    <div>
                        <h5 class="card-title ">New Likes</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{stats?.newLikes}}
                            <div class="spinner-border text-warning" *ngIf="!stats" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </h6>
                    </div>

                </div>
            </div>

        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div class="icon-sec">
                        <mat-icon class="card-icon">comment</mat-icon>
                    </div>
                    <div>
                        <h5 class="card-title">New Comments</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{stats?.newComment}}
                            <div class="spinner-border text-warning" *ngIf="!stats" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </h6>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div class="icon-sec">
                        <mat-icon class="card-icon">person_add</mat-icon>
                    </div>
                    <div>
                        <h5 class="card-title">New Users</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{stats?.newUsers}}
                            <div class="spinner-border text-warning" *ngIf="!stats" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </h6>
                    </div>

                </div>
            </div>



        </div>

    </div>

    <div class="row mx-2 my-2">
        <div class="col-md-3">
            <div class="card">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div class="icon-sec">
                        <mat-icon class="card-icon">peoples</mat-icon>
                    </div>
                    <div>
                        <h5 class="card-title">Total Users</h5>
                        <h6 class="card-subtitle mb-2 text-muted">
                            {{stats?.totalusers}}
                            <div class="spinner-border text-warning" *ngIf="!stats" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </h6>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div class="icon-sec">
                        <mat-icon class="card-icon">feed</mat-icon>
                    </div>
                    <div>
                        <h5 class="card-title">Total Posts</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{stats?.totalPosts}}
                            <div class="spinner-border text-warning" *ngIf="!stats" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </h6>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div class="icon-sec">
                        <mat-icon class="card-icon">favorite</mat-icon>
                    </div>
                    <div>
                        <h5 class="card-title">Total Likes</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{stats?.totalLikes}}
                            <div class="spinner-border text-warning" *ngIf="!stats" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </h6>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div class="icon-sec">
                        <mat-icon class="card-icon">comment</mat-icon>
                    </div>
                    <div>
                        <h5 class="card-title">Total Comments</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{stats?.totalComments}}
                            <div class="spinner-border text-warning" *ngIf="!stats" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </h6>
                    </div>


                </div>
            </div>



        </div>
    </div>
</div>
<div class="container outer">
    <h3 style="font-weight: 800;">Post Comments</h3>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <div>
        <div class="container comment-box" *ngFor="let item of comments">
            <div class="replay-user">{{item?.user?.name}}</div>
            <div class="comment-body">
                {{item?.comment}}

                <div class="viewreplay" (click)="getReplay(item?._id)">View replies</div>
            </div>

            <div class="replays">

                <div *ngIf='showreplay==item?._id'>
                    <div style="text-align: center;" *ngIf="replays.length <1"> No replies</div>
                    <div class="replay-box" *ngFor="let replay of replays">
                        <div class="replay-user">{{replay?.user?.name}}</div>
                        <div class="replay-body">
                            {{replay?.comment}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="comments.length<1"> No comments!!</div>

    <div>
        <button class="btn btn-danger" style="margin-top: 20px;" (click)="close()">Close</button>

    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  stats: any;
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getStatistics();
  }

  getStatistics() {
    this.api.statistics().subscribe((res: any) => {
      console.log(res);
      this.stats = res['data'];
    },
      (err: any) => {
        console.log(err);
      }
    );
  }

}

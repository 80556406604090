<div class="container" class="outer-box">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <table class="table">
        <tr class="head-table">
            <th class="head-table-item">Position</th>
            <th class="head-table-item">Description</th>
            <th class="head-table-item">Company Name</th>
            <th class="head-table-item">Company Email</th>

            <th class="head-table-item">No of vaccancy</th>

            <th class="head-table-item">Location</th>
            <th class="head-table-item">Action</th>


        </tr>
        <tr *ngFor="let jobs of job">
            <td>{{jobs.position}}</td>
            <td>{{jobs.description}}</td>
            <td>{{jobs.company_name}}</td>
            <td>{{jobs.company_email}}</td>

            <td>{{jobs.no_of_vacancy}}</td>

            <td>{{jobs.location}}</td>

            <td style="display: flex; justify-content: space-between;">
                <button class="btn btn-secondary" matTooltip="Mark as closed" (click)="markAsclosed(jobs._id)">
                    <mat-icon aria-label="Side nav toggle icon">done</mat-icon>
                    
                </button>

            </td>

        </tr>
    </table>
    <div class="naviagation" *ngIf="job.length>0">
        <button class="btn-nav" [disabled]="jobpage<1" (click)="gotoPage('prev')">Prev</button>
        <!-- <span >{{userpage+1}}</span> -->
        <div>
            <div class="nav-pages" *ngFor="let page of pagelist;let i as index">
                <button *ngIf="i==1 || i==2 || i>=pagelist.length-1||i>=pagelist.length" [ngClass]="{'page-btn-active':page==jobpage+1}" (click)="gotopagebynumber(page)" class="page-btn">
                    {{page}}
                </button>
            </div>
        </div>
        <button class="btn-nav" [disabled]="job?.length<10" (click)="gotoPage('next')">Next</button>
    </div>
</div>
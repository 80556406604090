import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router, private api: ApiService) { }

  ngOnInit(): void {
    this.getUserInfo();

    if (this.api.Permissions.includes('SETTINGS')) {
      this.router.navigate(['/overview']);
    }
    else if (this.api.Permissions.includes('USERS')) {

      this.router.navigate(['users']);
    }
    else if (this.api.Permissions.includes('POST')) {
      this.router.navigate(['posts']);
    }
    else if (this.api.Permissions.includes('BLOOD')) {

      this.router.navigate(['bloodbank']);
    }
    else if (this.api.Permissions.includes('HOTELS')) {

      this.router.navigate(['hospitality']);
    }
    else if (this.api.Permissions.includes('ENQUIRY')) {

      this.router.navigate(['enquiry']);
    }
    else if (this.api.Permissions.includes('JOB')) {

      this.router.navigate(['jobs']);
    }
    else if (this.api.Permissions.includes('SETTINGS')) {
      this.router.navigate(['settings']);
    }
    else if (this.api.Permissions.includes('REPORTING')) {
      this.router.navigate(['reporting']);
    }
    else if (this.api.Permissions.includes('BANNERS')) {
      this.router.navigate(['banner']);
    }



  }

  getUserInfo() {
    this.api.userInfo().subscribe((res: any) => {

      this.api.Permissions = res['data'].permissions;
    },
      err => {
        console.log(err);
      }
    );
  }

}

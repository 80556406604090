<h3 style="font-weight: 900;">Select location from map</h3>
<div class="form-group">

    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Your Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
</div>
<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
</agm-map>

<div class="mt-4"><span style="font-weight: 900;">Address:</span> {{address}}</div>



<button class="btn btn-success mt-3" (click)="close()">DONE</button>
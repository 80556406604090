import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  constructor(private api:ApiService,private spinner:NgxSpinnerService) { }
  addData:any;
  addtype:any;
  addFrequency:any;
  typeEdit:boolean = false;
  freqEdit:boolean = false;
  ngOnInit(): void {
    this.getadminSettings();
  }


  getadminSettings(){
    this.api.getAdminSettings().subscribe((res:any)=>{
      console.log(res);
      this.addData = res.adminSettings?.parameters;
      this.addtype = this.addData?.adtype?.defaultValue?.value;
      this.addFrequency = this.addData?.adfrequency?.defaultValue?.value;
    },
    (err)=>{
      console.log(err);
    }
    );
  }


  UpdateAdminSettings(type:any){
    
    this.spinner.show();

    let data = {
      "adtype": this.addtype,
      "adfrequency":this.addFrequency.toString(),
    }
    this.api.editAdminSettings(data).subscribe((res:any)=>{
      console.log(res);

      this.getadminSettings();
      if(type=='adtype'){
        this.typeEdit = false;
      }
      else if(type=='adfrequency'){
        this.freqEdit = false;
      }
      this.spinner.hide();
    },
    (err)=>{
      console.log(err);
      this.spinner.hide();
    }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { AddbllodbankComponent } from './addbllodbank/addbllodbank.component';
import { EditbloodbankComponent } from './editbloodbank/editbloodbank.component';

@Component({
  selector: 'app-bloodbank',
  templateUrl: './bloodbank.component.html',
  styleUrls: ['./bloodbank.component.scss']
})
export class BloodbankComponent implements OnInit {

  bloodList: any ;
  bloodbanklist:any;
  bloodUser:any;
  activeType:any='BLOOD_BANK';
  constructor(private api:ApiService,private dailog:MatDialog,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getBloodbank();
  }
  
  getBloodbank() {
    this.spinner.show();
    this.api.getAllBlood().subscribe(
      (data:any) => {
        console.log(data);
        this.bloodList = data['data'];
        this.bloodbanklist = this.bloodList.filter((item:any) => item.type == 'BLOOD_BANK');
        this.bloodUser = this.bloodList.filter((item:any) => item.type == 'USER');

        console.log(this.bloodbanklist);
        console.log(this.bloodUser);
        this.spinner.hide();

      },
      error => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  editBloodbank(item:any){
    
    const dialogRef = this.dailog.open(EditbloodbankComponent, {
      width: '500px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getBloodbank();
    });


  }
  deleteBloodBank(id:any){

  }

  addBloodBank(){
    const dialogRef = this.dailog.open(AddbllodbankComponent, {
      width: '500px',
      data: {type:'BLOOD_BANK'}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getBloodbank();
    });
  }



}

<div class="container reporting-body">
    <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>  -->

    <button class="btn back-btn" routerLink="../..">
        <mat-icon aria-label="Side nav toggle icon">arrow_back_ios</mat-icon>

         Back</button>
    <div class="typeswitch">
        <div class="type" [ngClass]="{'tab_btn' : activeType == 'POST','tab_btn1' : activeType == 'USER','tab_btn2' : activeType == 'REASON'}" (click)="activeType='POST'">Post</div>
        <div class="type" [ngClass]="{'tab_btn' : activeType == 'USER','tab_btn1' : activeType == 'REASON','tab_btn2' : activeType == 'POST'}" (click)="activeType='USER'">Users</div>
        <div class="type" [ngClass]="{'tab_btn' : activeType == 'REASON','tab_btn1' : activeType == 'POST','tab_btn2' : activeType == 'USER'}" (click)="activeType='REASON'">Reasons</div>
    </div>

    <div class="d-flex">
        <button class="btn   btn-delete" style="font-size: 12px;background-color: #f88106;color:white;" (click)="deletePost()">    
        <!-- <mat-icon aria-label="Side nav toggle icon" style="font-size: 1.250em;">delete</mat-icon> -->
        Delete Post
        </button>
    </div>
    <div *ngIf="activeType=='REASON'">
        <div class="reporting-box">

            <div style="font-size: 12px; color: black">{{reportReasons}}</div>
        </div>
    </div>
    <div *ngIf="activeType=='USER'">
        <div class="reporting-box" *ngFor="let user of reportedUsers">

            <div style="font-size: 12px; color: black;">{{user.name}}</div>
        </div>
    </div>


    <div *ngIf="activeType=='POST'">
        <div class="reporting-box">
            <div class="user-info">

                <div class="sec1">
                    <div class="user-icon">
                        {{reportedPost?.owner?.name[0]| uppercase}}
                    </div>
                    <div>
                        <div style="font-weight:bold">{{reportedPost?.owner?.name}}</div>
                        <div style="font-size: 8px;" class="date-string">{{reportedPost?.createdAt | date:'short'}}</div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="sec2" style="margin-right: 10px;">
                        <mat-icon aria-label="Side nav toggle icon">favorite</mat-icon><span style="font-size: 20px;"> {{reportedPost?.like}}</span>
                    </div>
                    <div class="sec2" (click)="openComment()" matTooltip="view comments">
                        <mat-icon aria-label="Side nav toggle icon">comment</mat-icon><span style="font-size: 20px;"> {{reportedPost?.comments}}</span>
                    </div>
                </div>
            </div>

            <div class="postdata" [innerHtml]="reportedPost?.title"></div>


            <div class="contents">
                <ng-image-slider [images]="postcontents[0]" #nav></ng-image-slider>





            </div>

        </div>
    </div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent implements OnInit {
  permissionlist:any;
  permission:any=[];
  loading:boolean=false;
  constructor(private ref:MatDialogRef<UserAccessComponent>,@Inject(MAT_DIALOG_DATA)public data:any ,
  private api:ApiService) { }

  ngOnInit(): void {
    this.getPermissionList();
    console.log(this.data);
    this.permission=this.data?.permissions;
    console.log(this.permission);
    

  }


  update(event:any,val:any){
    if(event.target.checked){
      console.log(val);
      
      this.permission.push(val);
    }
    else{
      this.permission.splice(this.permission.indexOf(val),1);
    }
    console.log(this.permission);

  }

  updatePermission(){
    this.loading=true;
   let temp= {
      "id":this.data?._id,
      "permissions": this.permission
  };

    this.api.setPermission(temp).subscribe((res:any)=>{
      console.log(res);
      this.loading=false;
      this.ref.close();
    },
    (err:any)=>{
      console.log(err);
    });
  }


  getPermissionList(){
    this.api.getPrmissions().subscribe((res:any)=>{
      console.log(res);
    },
    (err:any)=>{
      console.log(err);
    }
    );

  }


}

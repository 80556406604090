<div class="container banner-box">


    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>



    <button class="btn btn-primary btn-add" (click)="addBanner()"><mat-icon aria-label="Side nav toggle icon" class="addicon" >add</mat-icon></button>
    <div class="nodata" *ngIf="bannerList?.length<1">
        No banner
    </div>
    <table class="table" *ngIf="bannerList?.length>0">
        <thead>
            <tr class="head-table">
                <th class="head-table-item">Title</th>
                <th class="head-table-item">Banner Image</th>
                <th class="head-table-item">status</th>
                <th class="head-table-item">Action</th>

            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let banner of bannerList">
                <td>{{banner?.title}}</td>
                <td>
                    <img [src]="baseUrl+banner?.banner?.url" width="50px" height="50px" *ngIf="banner?.banner?.location=='LOCAL'" alt="">
                    <img [src]="banner?.banner?.url" width="50px" height="50px" *ngIf="banner?.banner?.location=='S3'" alt="">

                </td>
                <td>
                    <span>{{banner?.enabled?'Enabled':'Disabled'}}</span>

                    <td>
                        <button class="btn btn-edit" (click)="editBanner(banner)"><mat-icon aria-label="Side nav toggle icon" >edit</mat-icon></button>
                        <button class="btn btn-danger" (click)="deleteBanner(banner?._id)"><mat-icon aria-label="Side nav toggle icon">delete</mat-icon></button>
                    </td>
            </tr>
        </tbody>
    </table>







</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-addbllodbank',
  templateUrl: './addbllodbank.component.html',
  styleUrls: ['./addbllodbank.component.scss']
})
export class AddbllodbankComponent implements OnInit {

  constructor(private api:ApiService,private ref:MatDialogRef<AddbllodbankComponent>,@Inject (MAT_DIALOG_DATA) public data: any) { }

  name:any;
  location:any;
  contact:any;

  ngOnInit(): void {
  }

  addBloodBank(){
    let dat={
      "blood_bank_name":this.name,
      "location":this.location,
      "contact_no":this.contact
    }
    this.api.addBloodbank(dat).subscribe(res=>{
      this.ref.close();
    },
    err=>{
      console.log(err);
    })
  }

}

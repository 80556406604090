import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  comments:any;
  showreplay:any='none';
  replays: any;
  constructor(private ref:MatDialogRef<CommentsComponent>,private spinner:NgxSpinnerService,@Inject(MAT_DIALOG_DATA) public data: any,private api:ApiService) { }

  ngOnInit(): void {
    this.getComments();
  }

  getComments(){
    this.spinner.show();
    this.api.getPostsComments(this.data.id).subscribe((res:any)=>{
      console.log(res);
      this.comments=res['comments'];
      this.spinner.hide();
    },
    err=>{
      console.log(err);
    }
      );
  }

  close(){
    this.ref.close();
  }

  

  getReplay(id:any){
    this.showreplay=id;
    this.api.getPostCommentReplay(id).subscribe((res:any)=>{
      this.replays=res['comments'];
    } 
    );
  }


}

import { Component, OnInit, ViewChild, ElementRef, NgZone, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-mappicker',
  templateUrl: './mappicker.component.html',
  styleUrls: ['./mappicker.component.scss']
})
export class MappickerComponent implements OnInit {
  latitude: number = 0;
  longitude: number = 0;
  zoom: number = 0;
  address: string = '';
  location: any;
  private geoCoder: any;
  @ViewChild('search') public searchElementRef: ElementRef | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private ref: MatDialogRef<MappickerComponent>, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,) { }

  ngOnInit(): void {

    if (this.data?.type == 'update') {
      this.latitude = this.data.latitude;
      this.longitude = this.data.longitude;
      this.zoom = 8;
    }


    this.mapsAPILoader.load().then(() => {

      this.getCorrdinate();


      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef?.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  close() {
    this.ref.close({ 'lat': this.latitude, 'long': this.longitude, address: this.address });
  }


  markerDragEnd($event: google.maps.MouseEvent) {
    console.log($event);
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }




  getCorrdinate() {
    this.setCurrentLocation();
  }

}

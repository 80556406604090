import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingGuard implements CanActivate {

  constructor( private router: Router,private api:ApiService) {}
  
  canActivate( 
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const backtoteamlead = route.data.backtoteamlead || false;
      if(localStorage.getItem('access')?.includes('REPORTING')){

        return true;
      }
      else{
        return false;
      }

  }

  
}

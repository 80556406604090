import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar,MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private api:ApiService,private snackBar: MatSnackBar,private spinner:NgxSpinnerService,private dialog:MatDialog) { }
  enq:any;
  isLoading:boolean = true;
  enquirypage:any=0;
  ngOnInit(): void {
    this.spinner.show();
    this.listEnq();

  }
  listEnq(){
    this.spinner.show();
    this.api.getPendingenquiries().subscribe(
      (data:any) => {
        console.log(data);
       
        this.enq = data['contact'];
       
        this.spinner.hide();
      },
      (error) => {
        console.log(error);

        this.spinner.hide();
      }
    );
  }


  markAsCompleted(id:any){
    this.spinner.show();
    let data={
      'id':id,
    }
    this.api.enquirymarkascompleted(data).subscribe(
      (data:any) => {
        console.log(data);
        this.listEnq();
        this.spinner.hide();
        this.openSnackBar(3,'Enquiry Marked as Completed','Close');
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }


  gotoPage(to:any){
    if(to=='next'){
      this.enquirypage++;
    }
    else{
      this.enquirypage--;
    }

    this.listEnq();
  }


  openSnackBar(duration:any,msg:any,action:any) {
    this.snackBar.open(msg,action, {
      duration: duration * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }


}

<div class="container">

    <div class="resort-box">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="true">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
        <div class="nodata" *ngIf="allReporting?.length<1">
            No Rating available
        </div>
        <table class="table" *ngIf="allReporting?.length>0">
            <tr class="head-table">
                <th class="head-table-item">Id</th>
                <th class="head-table-item">Number of Reports</th>
                <th class="head-table-item">Details</th>



            </tr>
            <tr *ngFor="let report of allReporting">
                <td>{{report._id}}</td>
                <td>{{report.reaons.length}}</td>
                <td>
                    <div class="btn-action-1" [routerLink]="'details/'+report._id">
                        <mat-icon aria-label="Side nav toggle icon">launch</mat-icon>
                    </div>

                </td>

            </tr>
        </table>


        <div class="naviagation">
            <button class="btn-nav" [disabled]="userpage<1" (click)="gotoPage('prev')">Prev</button>
            <span>{{userpage+1}}</span>
            <button class="btn-nav" [disabled]="allReporting?.length<10" (click)="gotoPage('next')">Next</button>
        </div>
    </div>

</div>
<div class="container">

    <div class="form-group mt-3" style="position: relative;">
        <input type="text" class="form-control" placeholder="Search user" [(ngModel)]="name">
        <button class="btn btn-search" style="position: absolute; right: 0px; top:0px" (click)="userSearch()">
            <mat-icon style="font-size: 28px;display: flex; color: white;">search</mat-icon>
        </button>
    </div>
    <table class="table" *ngIf="userList">
        <tr class="head-table">
            <th class="head-table-item">Name</th>
            <th class="head-table-item">Actions</th>

        </tr>
        <tr *ngFor="let user of userList">
            <td>{{user.name}}</td>

            <!-- <td><img src="{{user.profile_image}}" alt="profile image" width="100px" height="100px"></td> -->
            <td style="display: flex; justify-content: space-between; align-items: center;">
                <div class="btn-action" [ngStyle]="{'color':user?.blocked?'red':'white'}" [matTooltip]="user?.blocked?'unblock user':'block user'" (click)="blockUser(user?._id,user?.blocked)">
                    <mat-icon aria-label="Side nav toggle icon">block</mat-icon>
                </div>
                <div class="btn-action" matTooltip="delete user" (click)="deleteUser(user?._id)">
                    <mat-icon aria-label="Side nav toggle icon">delete</mat-icon>
                </div>
                <div class="btn-action" [ngStyle]="{'color':user?.verified?'blue':'white'}" [matTooltip]="user?.verified?'undo verification':'verify'" (click)="verifyUser(user?._id,user?.verified,user?.name)">
                    <mat-icon aria-label="Side nav toggle icon">verified</mat-icon>
                </div>
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="changeRole(user?._id,user?.role)">
                          <mat-icon *ngIf="user?.role=='admin'">account_circle</mat-icon>
                          <mat-icon *ngIf="user?.role!='admin'">admin_panel_settings</mat-icon>
                          <span *ngIf="user?.role!='admin'">Set as admin</span>
                          <span *ngIf="user?.role=='admin'">Set as user</span>
                        </button>

                    </mat-menu>
                </div>
            </td>
        </tr>
    </table>


</div>
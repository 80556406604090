<div class="container" class="outer-box">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <table class="table">
        <tr class="head-table">
            <th class="head-table-item">Name</th>
            <th class="head-table-item">Mobile</th>
            <th class="head-table-item">Email</th>
            <th class="head-table-item">City</th>
            <th class="head-table-item">Role</th>
            <th class="head-table-item">Action</th>
        </tr>
        <tr *ngFor="let user of userList">
            <td>{{user.name}}</td>
            <td>{{user.phone_no}}</td>
            <td>{{user.email}}</td>
            <td>{{user.city}}</td>
            <td>{{user.role}}</td>
            <!-- <td><img src="{{user.profile_image}}" alt="profile image" width="100px" height="100px"></td> -->
            <td style="display: flex; justify-content: space-between; align-items: center;">
                <div class="btn-action" [ngStyle]="{'color':user?.blocked?'red':'white'}" [matTooltip]="user?.blocked?'unblock user':'block user'" (click)="blockUser(user?._id,user?.blocked)">
                    <mat-icon aria-label="Side nav toggle icon">block</mat-icon>
                </div>
                <div class="btn-action" matTooltip="delete user" (click)="deleteUser(user?._id)">
                    <mat-icon aria-label="Side nav toggle icon">delete</mat-icon>
                </div>
                <div class="btn-action" [ngStyle]="{'color':user?.verified?'blue':'white'}" [matTooltip]="user?.verified?'undo verification':'verify'" (click)="verifyUser(user?._id,user?.verified,user?.name)">
                    <mat-icon aria-label="Side nav toggle icon">verified</mat-icon>
                </div>
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="changeRole(user?._id,user?.role)">
                          <mat-icon *ngIf="user?.role=='admin'">account_circle</mat-icon>
                          <mat-icon *ngIf="user?.role!='admin'">admin_panel_settings</mat-icon>
                          <span *ngIf="user?.role!='admin'">Set as admin</span>
                          <span *ngIf="user?.role=='admin'">Set as user</span>
                        </button>

                    </mat-menu>
                </div>
            </td>
        </tr>
    </table>

    <div class="naviagation">
        <button class="btn-nav" [disabled]="userpage<1" (click)="gotoPage('prev')">Prev</button>
        <!-- <span >{{userpage+1}}</span> -->
        <div>
            <div class="nav-pages" *ngFor="let page of pagelist;let i as index">
                <button *ngIf="i==1 || i==2 || i>=pagelist.length-1||i>=pagelist.length" [ngClass]="{'page-btn-active':page==userpage+1}" (click)="gotopagebynumber(page)" class="page-btn">
                    {{page}}
                </button>
            </div>
        </div>
        <button class="btn-nav" [disabled]="userList?.length<10" (click)="gotoPage('next')">Next</button>
    </div>

</div>
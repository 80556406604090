import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { LoginComponent } from './login/login.component';
import { DashboardModule } from './dashboard/dashboard.module';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {path: '', loadChildren:()=>import('./dashboard/dashboard.module').then(m=>DashboardModule),canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToLogin }},
{path:'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
canActivate: [AngularFireAuthGuard],
data: { authGuardPipe: redirectLoggedInToHome }
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmboxComponent } from 'src/app/confirmbox/confirmbox.component';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-resorts',
  templateUrl: './resorts.component.html',
  styleUrls: ['./resorts.component.scss']
})
export class ResortsComponent implements OnInit {
  allHotels:any;
  userpage:any=0;

  constructor(private api:ApiService,private spinner: NgxSpinnerService,private dialog:MatDialog) { }

  ngOnInit(): void {
    this.getHotels(0);
  }


  getHotels(page:number){
    this.spinner.show();
    this.api.getHotel(page,'resort').subscribe((res:any)=>{
      console.log(res);
      this.allHotels = res['data'];
      this.spinner.hide();
    },
    (err:any)=>{
      console.log(err);
      this.spinner.hide();
    }
    
    )
  }


  gotoPage(to:any){
    if(to=='next'){
      this.userpage++;
    }
    else{
      this.userpage--;
    }

    this.getHotels(this.userpage);
  }

  deleteHotel(id: any) {

    this.dialog.open(ConfirmboxComponent, {
      data: {
        tasktype: 'delete',
        btnmsg: 'Delete',
        message: 'Are you sure you want to delete Resort?',
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
  
    this.spinner.show();
    this.api.deleteHotel(id).subscribe((data: any) => {
      console.log(data);
      this.spinner.hide();
      this.api.openSnackBar(3,"Resort deleted successfully", "ok");
      this.getHotels(this.userpage)
      
      // this.ref.close();
    }, (err: any) => {
      console.log(err);
      this.spinner.hide();
      this.api.openSnackBar(3,"Something went wrong !!", "ok");
    }
    );
  }
  else {
    this.spinner.hide();
  }

    });
}

}

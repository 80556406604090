import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { NavComponent } from '../nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { UsersComponent } from './users/users.component';
import { PostsComponent } from './posts/posts.component';
import { MatButtonModule } from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PostDetailsComponent } from './posts/post-details/post-details.component';
import {MatDialogModule} from '@angular/material/dialog';
import { UserActionsComponent } from './users/user-actions/user-actions.component';
import { CommentsComponent } from './posts/post-details/comments/comments.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BloodbankComponent } from './bloodbank/bloodbank.component';
import { EditbloodbankComponent } from './bloodbank/editbloodbank/editbloodbank.component';
import { FormsModule } from '@angular/forms';
import { AddbllodbankComponent } from './bloodbank/addbllodbank/addbllodbank.component';
import { SettingsComponent } from './settings/settings.component';
import { AdminUsersComponent } from './settings/admin-users/admin-users.component';
import { AdvertisementComponent } from './settings/advertisement/advertisement.component';
import { HospitalityComponent } from './hospitality/hospitality.component';
import { HotelsComponent } from './hospitality/hotels/hotels.component';
import { ResortsComponent } from './hospitality/resorts/resorts.component';
import { DetailsViewComponent } from './hospitality/details-view/details-view.component';
import { AddComponent } from './hospitality/add/add.component';
import { MappickerComponent } from './hospitality/add/mappicker/mappicker.component';
import { AgmCoreModule } from '@agm/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { RatingComponent } from './hospitality/details-view/rating/rating.component';
import { UserAccessComponent } from './settings/admin-users/user-access/user-access.component';
import { AddAdminComponent } from './settings/admin-users/add-admin/add-admin.component';
import { NgImageSliderModule } from 'ng-image-slider';
import {MatMenuModule} from '@angular/material/menu';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { JobsComponent } from './jobs/jobs.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FilterComponent } from './posts/filter/filter.component';
import { HotelActionsComponent } from './hospitality/hotels/hotel-actions/hotel-actions.component';
import { MediaEditComponent } from './hospitality/details-view/media-edit/media-edit.component';

import { UserSearchComponent } from './users/user-search/user-search.component';
import { UserOutletComponent } from './users/user-outlet/user-outlet.component';

import { ReportingComponent } from './reporting/reporting.component';
import { ReportingDetailsComponent } from './reporting/reporting-details/reporting-details.component';
import { ReportingListComponent } from './reporting/reporting-list/reporting-list.component';

import { BannerComponent } from './banner/banner.component';
import { EditbannerComponent } from './banner/editbanner/editbanner.component';
import { AddbannerComponent } from './banner/addbanner/addbanner.component';
import { OverviewComponent } from './overview/overview.component';
@NgModule({
  declarations: [
    DashboardComponent,
    NavComponent,
    UsersComponent,
    PostsComponent,
    PostDetailsComponent,
    UserActionsComponent,
    CommentsComponent,
    BloodbankComponent,
    EditbloodbankComponent,
    AddbllodbankComponent,
    SettingsComponent,
    AdminUsersComponent,
    AdvertisementComponent,
    HospitalityComponent,
    HotelsComponent,
    ResortsComponent,
    DetailsViewComponent,
    AddComponent,
    MappickerComponent,
    RatingComponent,
    UserAccessComponent,
    AddAdminComponent,
    EnquiryComponent,
    JobsComponent,
    FilterComponent,
    HotelActionsComponent,
    MediaEditComponent,

    UserSearchComponent,
    UserOutletComponent,

    ReportingComponent,
    ReportingDetailsComponent,
    ReportingListComponent,

    BannerComponent,
    EditbannerComponent,
    AddbannerComponent,
    OverviewComponent
    
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule,
    AgmCoreModule,
    MatSnackBarModule,
    NgImageSliderModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatDatepickerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule { }

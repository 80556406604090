import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import {MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent implements OnInit {
  name:any;
  email:any;
  password:any;
address:any;
phone_no:any;
uid:any;
submitted = false;
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private api:ApiService,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }


  createFirebaseAdmin(){
    
    if(this.email && this.password && this.name && this.address && this.phone_no){

    this.api.createFirebaseuser(this.email,this.password).then((res:any)=>{
      console.log(res);
      this.uid = res.user.uid;
      this.createAdmin();
    }
    ).catch((err:any)=>{
      console.log(err);
    }
    )
  }
  else{
    this.api.openSnackBar(5,'⚠️ Please fill all the fields','Close');
  }
  }

  createAdmin(){
    this.submitted = true;
    if (/^\d{10}$/.test(this.phone_no)) {
      // value is ok, use it
  } else {
     this.snackBar.open('⚠️ Please enter valid phone number','Close');
      
      return;
  }

    let data ={
      'name':this.name,
      'email':this.email,
      'password':this.password,
      'address':this.address,
      'phone_no':'+91'+this.phone_no,
     
    };
    
    this.api.createAdmin(data).subscribe((res:any)=>{
      console.log(res);
      if(res.success){
      this.openSnackBar(5,'✅ Admin user created','ok');
      
      this.back();
      }
      else{
        this.openSnackBar(5,'⚠️ '+res.message,'Close');
      }
    },
    (err:any)=>{
      console.log(err);
      this.openSnackBar(5,'⚠️ something went wrong !','Close');
    }
    );
  }

  back(){
    window.history.back();
  }



  openSnackBar(duration:any,msg:any,action:any) {
    this.snackBar.open(msg,action, {
      duration: duration * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  
}

<div class="container form-bdy">

    <h4 style="color: white;">Add new {{addtype}}</h4>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> uploading contents... </p>
    </ngx-spinner>
    <div class="row">
        <div class="form-group col-md-6 col-sm-12">
            <label for="name">Name</label>
            <input type="text" [ngClass]="{'is-invalid': !name&&submitted}" class="form-control" [(ngModel)]="name">
            <div class="invalid-feedback">
                Please enter name.
            </div>
        </div>
        <div class="form-group col-md-6 col-sm-12">
            <label for="name">Contact</label>
            <input type="text" (keypress)="checkChars($event)" [ngClass]="{'is-invalid': contact==''&&submitted ||contact.length<10&&submitted }" class="form-control" [(ngModel)]="contact">
            <div class="invalid-feedback">
                Please enter a valid contact number.
            </div>
        </div>

    </div>

    <div class="row">

        <div class="form-group col">
            <label for="name">Discription</label>
            <textarea class="form-control" [ngClass]="{'is-invalid': !discription&&submitted}" rows="5" [(ngModel)]="discription"></textarea>
            <div class="invalid-feedback">
                Please enter short discription.
            </div>
            <!-- <input type="text"  class="form-control" [(ngModel)]="discription"> -->
        </div>
        <div class="form-group col" *ngIf="addtype=='resort'">
            <label for="name">Per day cost</label>
            <input type="text" [ngClass]="{'is-invalid': !per_day_cost&&submitted}" class="form-control" [(ngModel)]="per_day_cost">
        </div>
    </div>

    <div class="row">
        <div class="form-group col-md-6 col-sm-12">
            <label for="name">Address</label>
            <input type="text" [ngClass]="{'is-invalid': !locationname&&submitted}" class="form-control" [(ngModel)]="locationname">
        </div>
        <div class="form-group col-md-6 col-sm-12">
            <label for="name">Website</label>
            <input type="text" class="form-control" [(ngModel)]="website">
            <div class="invalid-feedback">
                Please enter valid website.
            </div>
        </div>
    </div>


    <div class="row">
        <div class="form-group col-md-6 col-sm-12">
            <label for="name">Open time</label>
            <input type="text" [ngClass]="{'is-invalid': !open_time&&submitted}" class="form-control" [(ngModel)]="open_time">
        </div>
        <div class="form-group col-md-6 col-sm-12">
            <label for="name">Close time</label>
            <input type="text" [ngClass]="{'is-invalid': !close_time&&submitted}" class="form-control" [(ngModel)]="close_time">
        </div>
    </div>

    <!-- <div class="form-group">
        <label for="name">Menu</label>
        <textarea class="form-control" [(ngModel)]="menu" name="c" id="c" cols="10" rows="5"></textarea>

    </div> -->

    <div class="filebox">

        <label style=" border: 1px solid white; padding:5px;width: 100%;" for="file-upload-menu" class="file-attach" [ngClass]="{'invalid-label': uploadedFiles.length==0&&submitted}">
             <mat-icon aria-hidden="false" class="more-btn" aria-label="Example home icon">upload_file</mat-icon>
             Upload menu
        </label>
        <input type="file" id="file-upload-menu" (change)="uploadContent($event,'menu')" class="form-control d-none">

        <div class="container ">


            <ng-image-slider *ngIf="menucontents.length > 0" [images]="menucontents" #nav></ng-image-slider>



            <!-- </div> -->




        </div>
    </div>

    <div class="filebox">

        <label style=" border: 1px solid white; padding:5px;width: 100%;" for="file-upload" class="file-attach" [ngClass]="{'invalid-label': uploadedFiles.length==0&&submitted}">
             <mat-icon aria-hidden="false" class="more-btn" aria-label="Example home icon">upload_file</mat-icon>
             Upload media
        </label>
        <input type="file" id="file-upload" (change)="uploadContent($event,'media')" class="form-control d-none">

        <div class="container ">


            <ng-image-slider *ngIf="uploadedFiles.length > 0" [images]="uploadedFiles" #nav></ng-image-slider>



            <!-- </div> -->




        </div>
    </div>

    <div>
        <div class="container mb-2">
            <p style="color: white;">{{location?.address}}</p>
        </div>

        <button class="btn btn-primary" style="display: flex;" (click)="setCoordinate()">
            <mat-icon>my_location</mat-icon>
            Pick location from map</button>


    </div>











</div>

<div class="footer">
    <button class="btn btn-save" (click)="addhotel()">Save </button>
    <button class="btn btn-cancel" (click)="cancel()">cancel </button>
</div>
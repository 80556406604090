<div class="container ad-box">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>

    <div class="item">
        <div style="min-width: 130px;">
            <Span style="font-weight: 600;">Add Type</Span> &nbsp; : <span *ngIf="!typeEdit">{{addtype}}</span></div>

        <select *ngIf="typeEdit" name="type" class="form-control" id="type" [(ngModel)]="addtype">
            <option value="FACEBOOK">Facebook</option>
            <option value="GOOGLE">Google</option>
        </select>

        <button class="btn edit-btn" *ngIf="!typeEdit" (click)="typeEdit=true">
            <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
        </button>
        <button class="btn done-btn" *ngIf="typeEdit" (click)="UpdateAdminSettings('adtype')">
            <mat-icon aria-label="Side nav toggle icon">done</mat-icon>
        </button>

    </div>
    <div class="item">
        <div style="min-width: 130px;">
            <Span style="font-weight: 600;">Add Frequency</Span> &nbsp; : <span *ngIf="!freqEdit">{{addFrequency}}</span></div>

        <input type="number" *ngIf="freqEdit" class="form-control" [(ngModel)]="addFrequency">
        <button class="btn edit-btn" *ngIf="!freqEdit" (click)="freqEdit=true">
            <mat-icon aria-label="Side nav toggle icon">edit</mat-icon>
        </button>
        <button class="btn done-btn" *ngIf="freqEdit" (click)="UpdateAdminSettings('adfrequency')">
            <mat-icon aria-label="Side nav toggle icon">done</mat-icon>
        </button>
    </div>


</div>
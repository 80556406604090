import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  constructor(private api:ApiService,private spinner:NgxSpinnerService,private dialog:MatDialog) { }
 job :any;
  isLoading:boolean = true;
  jobpage:any=0;
  pagelist: any = [1];
  ngOnInit(): void {
    this.spinner.show();
    this.listJob();
  }
listJob(){
  console.log("page number", this.jobpage);
  this.spinner.show();
    this.api.getJobs(this.jobpage).subscribe(
      (data:any) => {
        console.log('joblist',data);
        if(data['page']>0){
        this.job = data['post'];
        if(!this.pagelist.includes(data['page'])){
          this.pagelist.push(data['page']);
        }
        }
        else{
          this.jobpage=0;
          this.api.getJobs(this.jobpage).subscribe(
            (data:any) => {
              console.log(data);
              this.job = data['post'];
            });
        }
        this.spinner.hide();
      },
      (error) => {
        console.log(error);

        this.spinner.hide();
      }
    );
  }
  gotoPage(to:any){
    if(to=='next'){
      this.jobpage++;
    }
    else{
      this.jobpage--;
    }

    this.listJob();
}
gotopagebynumber(page:any){
  console.log(page);
  
  this.jobpage = page-1;
  this.listJob();
}


  markAsclosed(id:any){
    let data={
      "job_id":id
    }
    this.spinner.show();
    this.api.jobmarkClosed(data).subscribe(
      (data:any) => {
        console.log(data);
        this.listJob();
        this.spinner.hide();
      },
      (error:any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }


}
